import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import styles from './MobileEditPositionScreen.module.scss';
import MobileScreenLayout from '../../layout/MobileScreenLayout/MobileScreenLayout';
import _t from 'counterpart';
import { useGoBackTo } from '../../hooks/useGoBackTo';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../storeHooks';
import { isSet } from '../../helpers';
import Alert from 'components/Alert/Alert';
import EditPositionForm from 'components/EditPositionForm/EditPositionForm';
const MobileEditPositionScreen = () => {
    const { positionId } = useParams();
    const positions = useAppSelector((state) => state.positions);
    const positionToEdit = Object.values(positions).find((position) => position.positionId === Number(positionId));
    const goBack = useGoBackTo('/trading/positions');
    useEffect(() => {
        if (positionToEdit !== undefined && positionToEdit.status !== 'open') {
            goBack();
        }
    }, [positionId, positionToEdit]);
    return (_jsx(MobileScreenLayout, Object.assign({ className: styles.container, title: _t('main.modify_position', { orderId: `#${positionToEdit === null || positionToEdit === void 0 ? void 0 : positionToEdit.positionId}` }), onBackClick: goBack }, { children: isSet(positionToEdit) ? (_jsx(EditPositionForm, { onGoBack: goBack, position: positionToEdit })) : (_jsx(Alert, { variant: 'error', title: _t('error.oops'), description: _t('error.position_not_found') })) })));
};
export default React.memo(MobileEditPositionScreen);
