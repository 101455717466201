import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import _t from 'counterpart';
import TradingSidebarLayout from 'components/TradingSidebarLayout/TradingSidebarLayout';
import { useGoBackTo } from '../../hooks/useGoBackTo';
import EditOrderForm from 'components/EditOrderForm/EditOrderForm';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../storeHooks';
import { isSet } from '../../helpers';
import Alert from 'components/Alert/Alert';
const EditOrderSidebar = () => {
    const { orderId } = useParams();
    const orders = useAppSelector((state) => state.orders);
    const orderToEdit = orders.find((order) => order.id === Number(orderId));
    const goBack = useGoBackTo('/trading');
    return (_jsx(TradingSidebarLayout, Object.assign({ title: orderToEdit ? _t('main.edit_order_with_id', { orderId: `#${orderToEdit.positionId}` }) : _t('main.edit_order'), onBackClick: goBack }, { children: isSet(orderToEdit) ? (_jsx(EditOrderForm, { onGoBack: goBack, order: orderToEdit })) : (_jsx(Alert, { variant: 'error', title: _t('error.oops'), description: _t('error.order_not_found') })) })));
};
export default React.memo(EditOrderSidebar);
