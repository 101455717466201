import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styles from './PositionDetails.module.scss';
import _t from 'counterpart';
import DetailItem from 'components/InstrumentDetails/DetailItem';
import { FiFileText, FiTrendingUp } from 'react-icons/fi';
import CustomIcon from 'components/CustomIcon/CustomIcon';
import halfIcon from '../../assets/icons/half.svg?url';
import { formatDateTime } from '../../helpers';
import { formatCurrency } from 'utils/currency';
import { useAppSelector } from '../../storeHooks';
import classNames from 'classnames';
const PositionDetails = ({ position, decimals }) => {
    var _a, _b, _c, _d, _e, _f;
    const accountCurrency = useAppSelector((state) => state.user.accountCurrency);
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx("div", Object.assign({ className: styles.titleWrapper }, { children: _jsx("span", Object.assign({ className: styles.title }, { children: _t('main.details').toUpperCase() })) })), _jsxs("div", Object.assign({ className: styles.content }, { children: [_jsx(DetailItem, { icon: _jsx(FiFileText, { size: 16, className: styles.icon }), label: _t('main.opened_at'), value: `${(_a = position.openPrice) === null || _a === void 0 ? void 0 : _a.toFixed(decimals)} @ ${formatDateTime(position.openedAt)}`, valueClassName: styles.monospaceValue }), _jsxs("div", Object.assign({ className: styles.row }, { children: [_jsx(DetailItem, { icon: _jsx(CustomIcon, { src: halfIcon, alt: 'half', size: 'small', className: styles.icon }), label: _t('main.volume'), value: (_c = (_b = position.size) === null || _b === void 0 ? void 0 : _b.toString()) !== null && _c !== void 0 ? _c : '-', valueClassName: styles.monospaceValue }), _jsx(DetailItem, { icon: _jsx(FiTrendingUp, { size: 16, className: styles.icon }), label: _t('main.profit'), value: formatCurrency((_d = position.profitLoss) !== null && _d !== void 0 ? _d : 0, accountCurrency), valueClassName: classNames(styles.monospaceValue, {
                                    [styles.profitValuePositive]: ((_e = position.profitLoss) !== null && _e !== void 0 ? _e : 0) >= 0,
                                    [styles.profitValueNegative]: ((_f = position.profitLoss) !== null && _f !== void 0 ? _f : 0) < 0,
                                }) })] }))] }))] })));
};
export default React.memo(PositionDetails);
