import { useLocation } from 'react-router-dom';
import React, { useContext, useEffect, useRef, useState } from 'react';
import * as queryString from 'query-string';
import ToastContext from 'components/Toast/ToastContext';
import { useAppSelector } from './storeHooks';
import { usePrevious } from './hooks/usePrevious';
export const useQueryParams = () => {
    const { search } = useLocation();
    return React.useMemo(() => queryString.parse(search), [search]);
};
export const useToastContext = () => {
    return useContext(ToastContext);
};
export const useForwardRef = (ref, initialValue = null) => {
    const targetRef = useRef(initialValue);
    useEffect(() => {
        if (!ref)
            return;
        if (typeof ref === 'function') {
            ref(targetRef.current);
        }
        else {
            ref.current = targetRef.current;
        }
    }, [ref]);
    return targetRef;
};
export const useOrdersWithChangedStatus = () => {
    const orders = useAppSelector((state) => state.orders);
    const previousOrders = usePrevious(orders);
    const receivedPendingOrdersList = useAppSelector((state) => state.receivedPendingOrdersList);
    const previousReceivedPendingOrdersList = usePrevious(receivedPendingOrdersList);
    const [newOrders, setNewOrders] = useState(null);
    const [closedOrders, setClosedOrders] = useState(null);
    useEffect(() => {
        if (!previousReceivedPendingOrdersList) {
            return;
        }
        if (previousOrders === undefined) {
            return;
        }
        const tempClosedOrders = [];
        const tempNewOrders = [];
        const previousOrdersArray = previousOrders;
        for (let i = 0; i < orders.length; i++) {
            const order = orders[i];
            const previousOrderIndex = previousOrdersArray.findIndex((prevOrder) => prevOrder.positionId === order.positionId);
            if (previousOrderIndex === -1) {
                tempNewOrders.push(order);
            }
            else {
                const previousOrder = previousOrdersArray[previousOrderIndex];
                if (previousOrder && previousOrder.status !== order.status) {
                    tempClosedOrders.push(order);
                }
            }
        }
        setNewOrders(tempNewOrders);
        setClosedOrders(tempClosedOrders);
    }, [previousOrders, previousReceivedPendingOrdersList, orders]);
    return { newOrders, closedOrders };
};
export const usePositionsWithChangedStatus = () => {
    const positions = useAppSelector((state) => state.positions);
    const receivedOpenPositionsList = useAppSelector((state) => state.receivedOpenPositionsList);
    const previousReceivedOpenPositionsList = usePrevious(receivedOpenPositionsList);
    const previousPositions = usePrevious(positions);
    const [newPositions, setNewPositions] = useState(null);
    const [closedPositions, setClosedPositions] = useState(null);
    useEffect(() => {
        if (!previousReceivedOpenPositionsList) {
            return;
        }
        if (previousPositions === undefined) {
            return;
        }
        const tempClosedPositions = [];
        const tempNewPositions = [];
        const positionsArray = Object.values(positions);
        const previousPositionsArray = Object.values(previousPositions);
        for (let i = 0; i < positionsArray.length; i++) {
            const position = positionsArray[i];
            const previousPositionIndex = previousPositionsArray.findIndex((prevPos) => prevPos.positionId === position.positionId);
            if (previousPositionIndex === -1) {
                tempNewPositions.push(position);
            }
            else {
                const previousPosition = previousPositionsArray[previousPositionIndex];
                if (previousPosition && previousPosition.status !== position.status && position.status === 'closed') {
                    tempClosedPositions.push(position);
                }
            }
        }
        setNewPositions(tempNewPositions);
        setClosedPositions(tempClosedPositions);
    }, [previousPositions, positions, previousReceivedOpenPositionsList]);
    return { newPositions, closedPositions };
};
