import { ApiMessages } from '../ApiMessages';
export const ERROR_CLEAR = 'ERROR_CLEAR';
export const ERROR_SHOW = 'ERROR_SHOW';
export const DISPLAY_ERROR = 'DISPLAY_ERROR';
export const API_DISCONNECTED = 'API_DISCONNECTED';
export const API_ERROR = 'API_ERROR';
export const API_RECONNECTING = 'API_RECONNECTING';
export const API_RECONNECT_REQUEST = 'API_RECONNECT';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_SAVE_CREDENTIALS = 'USER_SAVE_CREDENTIALS';
export const USER_CLEAR_CREDENTIALS = 'USER_CLEAR_CREDENTIALS';
export const USER_SAVE_SOUND = 'USER_SAVE_SOUND';
export const USER_SAVE_BELL_SOUND = 'USER_SAVE_BELL_SOUND';
export const USER_SAVE_LANGUAGE = 'USER_SAVE_LANGUAGE';
export const USER_SAVE_CHART = 'USER_SAVE_CHART';
export const REQUEST_INSTRUMENTS = 'REQUEST_INSTRUMENTS';
export const INSTRUMENTS_RECEIVED = 'INSTRUMENTS_RECEIVED';
export const INSTRUMENTS_UPDATED = 'INSTRUMENTS_UPDATED';
export const INSTRUMENTS_SET_ACTIVE = 'INSTRUMENTS_SET_ACTIVE';
export const QUOTE_RECEIVED = 'QUOTE_RECEIVED';
export const QUOTES_RECEIVED = 'QUOTES_RECEIVED';
export const APPEND_QUOTE_FOR_SYMBOL = 'APPEND_QUOTE_FOR_SYMBOL';
export const SET_QUOTES_FOR_SYMBOL = 'SET_QUOTE_FOR_SYMBOL';
export const MERGE_QUOTES = 'MERGE_QUOTES';
export const SET_QUOTES = 'SET_QUOTES';
export const PRIME_QUOTES_RECEIVED = 'PRIME_QUOTES_RECEIVED';
export const PRIME_QUOTES_SET = 'PRIME_QUOTES_SET';
export const POSITION_UPDATED = 'POSITION_UPDATED';
export const POSITION_ADDED = 'POSITION_ADDED';
export const BALANCE_UPDATED = 'BALANCE_UPDATED';
export const BALANCE_ADJUSTMENT_FROM_PL_UPDATED = 'BALANCE_ADJUSTMENT_FROM_PL_UPDATED';
export const EXECUTE_PENDING_BALANCE_UPDATE = 'EXECUTE_PENDING_BALANCE_UPDATE';
export const POSITION_CLOSED = 'POSITION_CLOSED';
export const POSITION_OPEN_REQUEST = 'POSITION_OPEN_REQUEST';
export const POSITION_OPENED = 'POSITION_OPENED';
export const POSITION_STATUS = 'POSITION_STATUS';
export const TOGGLE_POSITIONS_DRAWER = 'TOGGLE_POSITIONS_DRAWER';
export const POSITIONS_SET_ACTIVE = 'POSITIONS_SET_ACTIVE';
export const POSITIONS_CLEAR_ACTIVE = 'POSITIONS_CLEAR_ACTIVE';
export const POSITIONS_CLOSE_SINGLE_REQUESTED = 'POSITIONS_CLOSE_SINGLE_REQUESTED';
export const POSITIONS_CLOSE_SINGLE_FINISHED = 'POSITIONS_CLOSE_SINGLE_FINISHED';
export const POSITION_CLOSING_REVERT = 'POSITION_CLOSING_REVERT';
export const POSITIONS_CLOSE_ALL_REQUESTED = 'POSITIONS_CLOSE_ALL_REQUESTED';
export const POSITIONS_CLOSE_ALL_FINISHED = 'POSITIONS_CLOSE_ALL_FINISHED';
export const RECEIVED_OPENED_POSITIONS_LIST = 'RECEIVED_OPENED_POSITIONS_LIST';
export const OPEN_POSITIONS_LIST_UPDATE = 'OPEN_POSITIONS_LIST_UPDATE';
export const API_LISTENER_CONNECTED = 'API_LISTENER_CONNECTED';
export const DEMO_API_LISTENER_CONNECTED = 'DEMO_API_LISTENER_CONNECTED';
export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_REJECT = 'LOGIN_REJECT';
export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const REQUEST_OAUTH_TOKENS = 'REQUEST_OAUTH_TOKENS';
export const SET_OAUTH_TOKENS = 'SET_OAUTH_TOKENS';
export const REFRESH_TOKEN_AND_LOGIN = 'REFRESH_TOKEN_AND_LOGIN';
export const AUTO_LOGIN_REQUESTED = 'AUTO_LOGIN_REQUESTED';
export const SET_DEMO_LOGIN_FLAG = 'SET_DEMO_LOGIN_FLAG';
export const UNSET_DEMO_LOGIN_FLAG = 'UNSET_DEMO_LOGIN_FLAG';
export const SET_LOGIN_STATE_FOR_AUTO_LOGIN = 'SET_LOGIN_STATE_FOR_AUTO_LOGIN';
export const HISTORY_SET_TIMERANGE = 'HISTORY_SET_TIMERANGE';
export const SET_CHART_RESOLUTION = 'SET_CHART_RESOLUTION';
export const HISTORY_QUOTES_RECEIVED = 'HISTORY_QUOTES_RECEIVED';
export const HISTORY_QUOTES_LOADING = 'HISTORY_QUOTES_LOADING';
export const SET_CANDLES_DATA = 'SET_CANDLES_DATA';
export const HISTORY_UPDATED = 'HISTORY_UPDATED';
export const SHOW_DURATION_SELECTION = 'SHOW_DURATION_SELECTION';
export const SHOW_PROFIT_LOSS_SELECTION = 'SHOW_PROFIT_LOSS_SELECTION';
export const SHOW_INVEST_SELECTION = 'SHOW_INVEST_SELECTION';
export const HIDE_OVERLAY = 'HIDE_OVERLAY';
export const SELECT_TRADE_DURATION = 'SELECT_TRADE_DURATION';
export const SET_TRADE_DURATION = 'SET_TRADE_DURATION';
export const SELECT_TRADE_PROFIT_LOSS = 'SELECT_TRADE_PROFIT_LOSS';
export const SET_TRADE_PROFIT_LOSS = 'SET_TRADE_PROFIT_LOSS';
export const SAVE_INSTRUMENT_PROFIT_LOSS = 'SAVE_INSTRUMENT_PROFIT_LOSS';
export const SELECT_TRADE_INVEST = 'SELECT_TRADE_INVEST';
export const LEADERBOARD_RANKING_REQUEST = 'LEADERBOARD_RANKING_REQUEST';
export const LEADERBOARD_RANKING_REFRESH_REQUEST = 'LEADERBOARD_RANKING_REFRESH_REQUEST';
export const LEADERBOARD_RANKING_REJECT = 'LEADERBOARD_RANKING_REJECT';
export const LEADERBOARD_RANKING_SUCCESS = 'LEADERBOARD_RANKING_SUCCESS';
export const ACCOUNT_HISTORY_REQUEST = 'ACCOUNT_HISTORY_REQUEST';
export const SET_ACCOUNT_HISTORY = 'SET_ACCOUNT_HISTORY';
export const LOGOFF = 'LOGOFF';
export const LOGGED_OFF = 'LOGGED_OFF';
export const SET_ACTIVE_SYMBOL = 'SET_ACTIVE_SYMBOL';
export const TOGGLE_DEV_MENU = 'TOGGLE_DEV_MENU';
export const SET_DEV_MENU_SETTINGS = 'SET_DEV_MENU_SETTINGS';
export const SET_SOUNDBANK_LOADING_PERCENTAGE = 'SET_SOUNDBANK_LOADING_PERCENTAGE';
export const SET_SOUNDBANK_LOADING = 'SET_SOUNDBANK_LOADING';
export const SET_IS_ONLINE = 'SET_IS_ONLINE';
export const SET_SOUNDSTYLE = 'SET_SOUNDSTYLE';
export const SET_DONT_SHOW_INSTALL_CTA = 'SET_DONT_SHOW_INSTALL_CTA';
export const SET_DONT_SHOW_CHROME_INSTALL_CTA = 'SET_DONT_SHOW_CHROME_INSTALL_CTA';
export const SET_INSTALL_CTA_HIDDEN_AT = 'SET_INSTALL_CTA_HIDDEN_AT';
export const SET_PWA_INSTALL_PROMPT = 'SET_PWA_INSTALL_PROMPT';
export const RESET_ALL = 'RESET_ALL';
export const RECEIVED_FEATURE_FLAGS = 'RECEIVED_FEATURE_FLAGS';
export const SOCKET_CLOSED = 'SOCKET_CLOSED';
export const SOCKET_ERROR = 'SOCKET_ERROR';
export const COINS_BALANCE_UPDATED = 'COINS_BALANCE_UPDATED';
export const SET_SIDE_DRAWER_SHOWN = 'SET_SIDE_DRAWER_SHOWN';
export const UPDATE_USER_REQUESTED = 'UPDATE_USER_REQUESTED';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_REJECT = 'UPDATE_USER_REJECT';
export const FETCH_MESSAGES_REQUESTED = 'FETCH_MESSAGES_REQUESTED';
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_REJECT = 'FETCH_MESSAGES_REJECT';
export const FETCH_MESSAGES_UNREAD_COUNT_REQUESTED = 'FETCH_MESSAGES_UNREAD_COUNT_REQUESTED';
export const FETCH_MESSAGES_UNREAD_COUNT_SUCCESS = 'FETCH_MESSAGES_UNREAD_COUNT_SUCCESS';
export const FETCH_MESSAGES_UNREAD_COUNT_REJECT = 'FETCH_MESSAGES_UNREAD_COUNT_REJECT';
export const FETCH_MESSAGES_NEXT_PAGE_REQUESTED = 'FETCH_MESSAGES_NEXT_PAGE_REQUESTED';
export const FETCH_MESSAGES_NEXT_PAGE_SUCCESS = 'FETCH_MESSAGES_NEXT_PAGE_SUCCESS';
export const FETCH_MESSAGES_NEXT_PAGE_REJECT = 'FETCH_MESSAGES_NEXT_PAGE_REJECT';
export const FETCH_COINS_EXCHANGE_RATE_REQUESTED = 'FETCH_COINS_EXCHANGE_RATE_REQUESTED';
export const FETCH_COINS_EXCHANGE_RATE_SUCCESS = 'FETCH_COINS_EXCHANGE_RATE_SUCCESS';
export const FETCH_COINS_EXCHANGE_RATE_REJECT = 'FETCH_COINS_EXCHANGE_RATE_REJECT';
export const JOIN_COMPETITION_REQUESTED = 'JOIN_COMPETITION_REQUESTED';
export const JOIN_COMPETITION_SUCCESS = 'JOIN_COMPETITION_SUCCESS';
export const JOIN_COMPETITION_REJECT = 'JOIN_COMPETITION_REJECT';
export const SET_LEADERBOARD_USER_DETAILS = 'SET_LEADERBOARD_USER_DETAILS';
export const CURRENT_COMPETITION_REQUESTED = 'CURRENT_COMPETITION_REQUESTED';
export const CURRENT_COMPETITION_SUCCESS = 'CURRENT_COMPETITION_SUCCESS';
export const CURRENT_COMPETITION_REJECT = 'CURRENT_COMPETITION_REJECT';
export const RECEIVED_COMPETITION_RANK_UPDATE = 'RECEIVED_COMPETITION_RANK_UPDATE';
export const ENABLE_AUTO_LOGIN = 'ENABLE_AUTO_LOGIN';
export const DISABLE_AUTO_LOGIN = 'DISABLE_AUTO_LOGIN';
export const SET_RELEASE_VERSION = 'SET_RELEASE_VERSION';
export const RECEIVED_TREND_QUOTES = 'RECEIVED_TREND_QUOTES';
export const SET_LOW_MARGIN_MODAL_SHOWN = 'SET_LOW_MARGIN_MODAL_SHOWN';
export const LOGIN_LOADER_SHOWN = 'LOGIN_LOADER_SHOWN';
export const LOGIN_LOADER_HIDDEN = 'LOGIN_LOADER_HIDDEN';
export const SET_LOGGING_IN = 'SET_LOGGING_IN';
export const UNSET_LOGGING_IN = 'UNSET_LOGGING_IN';
export const SET_STOP_OUT_OCCURRED = 'SET_STOP_OUT_OCCURRED';
export const CLEAR_STOP_OUT_OCCURRED = 'CLEAR_STOP_OUT_OCCURRED';
export const SET_MAINTENANCE_MODE = 'SET_MAINTENANCE_MODE';
export const SET_INSTRUMENTS_VALID_TRADING_INTERVALS = 'SET_INSTRUMENTS_VALID_TRADING_INTERVALS';
export const SET_POSITIONS_TIME_LEFT = 'SET_POSITIONS_TIME_LEFT';
export const SET_RIGHT_SIDE_WIDGET = 'SET_RIGHT_SIDE_WIDGET';
export const SHOW_INSUFFICIENT_FUNDS_MODAL = 'SHOW_INSUFFICIENT_FUNDS_MODAL';
export const HIDE_INSUFFICIENT_FUNDS_MODAL = 'HIDE_INSUFFICIENT_FUNDS_MODAL';
export const RECEIVED_INSTRUMENT_TREND_MESSAGE = 'RECEIVED_INSTRUMENT_TREND_MESSAGE';
export const SHOW_INSTRUMENT_TREND_EXPLAINER_MODAL = 'SHOW_INSTRUMENT_TREND_EXPLAINER_MODAL';
export const HIDE_INSTRUMENT_TREND_EXPLAINER_MODAL = 'HIDE_INSTRUMENT_TREND_EXPLAINER_MODAL';
export const SET_INSTRUMENT_TO_PRESELECT = 'SET_PRESELECT_ACTIVE_INSTRUMENT';
export const SET_LOGIN_TIMED_OUT = 'SET_LOGIN_TIMED_OUT';
export const RECEIVED_TIMEOUT_ERROR = 'RECEIVED_TIMEOUT_ERROR';
export const UPDATE_USER_LOYALTY_LEVEL = 'UPDATE_USER_LOYALTY_LEVEL';
export const RECEIVED_TRADING_OPTIONS = 'RECEIVED_TRADING_OPTIONS';
export const SET_ORDERS = 'SET_ORDERS';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const ADD_ORDER = 'ADD_ORDER';
export const ADD_PRICE_ALERT = 'ADD_PRICE_ALERT';
export const REMOVE_PRICE_ALERT_AT_INDEX = 'REMOVE_PRICE_ALERT_AT_INDEX';
export const SET_IS_SIDEBAR_COLLAPSED = 'SET_IS_SIDEBAR_COLLAPSED';
export const ENABLE_CROSSHAIR_PRICE_SELECTION = 'ENABLE_CROSSHAIR_PRICE_SELECTION';
export const DISABLE_CROSSHAIR_PRICE_SELECTION = 'DISABLE_CROSSHAIR_PRICE_SELECTION';
export const SET_LIVE_CROSSHAIR_PRICE = 'SET_LIVE_CROSSHAIR_PRICE';
export const SELECT_CURRENT_CROSSHAIR_PRICE = 'SELECT_CURRENT_CROSSHAIR_PRICE';
export const RECEIVED_MARGIN_CALL_LEVEL = 'RECEIVED_MARGIN_CALL_LEVEL';
export const SET_CONNECTION_READY = 'SET_CONNECTION_READY';
export const FETCH_GREY_LABEL_LOGO_REQUESTED = 'FETCH_GREY_LABEL_LOGO_REQUESTED';
export const FETCH_GREY_LABEL_LOGO_SUCCESS = 'FETCH_GREY_LABEL_LOGO_SUCCESS';
export const FETCH_GREY_LABEL_LOGO_REJECTED = 'FETCH_GREY_LABEL_LOGO_REJECTED';
export const HIDE_DEPOSIT_WITHDRAWAL_MODAL = 'HIDE_DEPOSIT_WITHDRAWAL_MODAL';
export const SHOW_DEPOSIT_WITHDRAWAL_MODAL = 'SHOW_DEPOSIT_WITHDRAWAL_MODAL';
export const HIDE_MARKET_CLOSED_ERROR_MODAL = 'HIDE_MARKET_CLOSED_ERROR_MODAL';
export const SHOW_MARKET_CLOSED_ERROR_MODAL = 'SHOW_MARKET_CLOSED_ERROR_MODAL';
export const ADD_ACTIVE_INSTRUMENT_SUBSCRIPTION_ID = 'ADD_ACTIVE_INSTRUMENT_SUBSCRIPTION_ID';
export const ADD_INSTRUMENTS_BATCH_SUBSCRIPTION_ID = 'ADD_INSTRUMENTS_BATCH_SUBSCRIPTION_ID';
export const apiActions = {
    SEND: 'API/SEND',
    LOGIN: 'API/LOGIN',
};
export function errorClear() {
    return {
        type: ERROR_CLEAR,
    };
}
export function errorShow(type, message, extra = null) {
    return {
        type: ERROR_SHOW,
        payload: {
            type,
            message,
            extra,
        },
    };
}
export function displayError(type, message, duration = 3500, extra = null) {
    return {
        type: DISPLAY_ERROR,
        payload: { type, message, duration, extra },
    };
}
export function setDemoLoginFlag() {
    return {
        type: SET_DEMO_LOGIN_FLAG,
    };
}
export function unsetDemoLoginFlag() {
    return {
        type: UNSET_DEMO_LOGIN_FLAG,
    };
}
export function userSaveSound(sound) {
    return {
        type: USER_SAVE_SOUND,
        payload: sound,
    };
}
export function userSaveBellSound(bellSound) {
    return {
        type: USER_SAVE_BELL_SOUND,
        payload: { bellSound },
    };
}
export function userSaveChartType(chart) {
    return {
        type: USER_SAVE_CHART,
        payload: chart,
    };
}
export function userSaveLanguage(language, skipUserUpdate = false) {
    return {
        type: USER_SAVE_LANGUAGE,
        payload: { language, skipUserUpdate },
    };
}
export function showLoading() {
    return {
        type: SHOW_LOADING,
    };
}
export function hideLoading() {
    return {
        type: HIDE_LOADING,
    };
}
export function handleApiListenerConnected() {
    return {
        type: API_LISTENER_CONNECTED,
    };
}
export function demoApiListenerConnected() {
    return {
        type: DEMO_API_LISTENER_CONNECTED,
    };
}
export function updateInstruments(instruments, timezone = '') {
    return {
        type: INSTRUMENTS_UPDATED,
        payload: { instruments, timezone },
    };
}
export function receiveQuote(newQuote) {
    return {
        type: QUOTE_RECEIVED,
        payload: newQuote,
    };
}
export function receiveQuotes(quotes) {
    return {
        type: QUOTES_RECEIVED,
        payload: { quotes },
    };
}
export function appendQuoteForSymbol(symbol, quote) {
    return {
        type: APPEND_QUOTE_FOR_SYMBOL,
        payload: { symbol, quote },
    };
}
export function setQuotesForSymbol(symbol, quotes) {
    return {
        type: SET_QUOTES_FOR_SYMBOL,
        payload: { symbol, quotes },
    };
}
export function setQuotes(quotes) {
    return {
        type: SET_QUOTES,
        payload: { quotes },
    };
}
export function mergeQuotes(quotes) {
    return {
        type: MERGE_QUOTES,
        payload: { quotes },
    };
}
export function receivePrimeQuotes(quotes) {
    return {
        type: PRIME_QUOTES_RECEIVED,
        payload: { quotes },
    };
}
export function primeQuotesSet() {
    return {
        type: PRIME_QUOTES_SET,
    };
}
export function updatePosition(position) {
    return {
        type: POSITION_UPDATED,
        payload: { position },
    };
}
export function addPosition(position) {
    return {
        type: POSITION_ADDED,
        payload: { position },
    };
}
export function updateBalance(balanceMessage) {
    return {
        type: BALANCE_UPDATED,
        payload: balanceMessage,
    };
}
export function executePendingBalanceUpdate() {
    return {
        type: EXECUTE_PENDING_BALANCE_UPDATE,
    };
}
export function openPosition(symbol, side, size, duration, invest) {
    return {
        type: POSITION_OPEN_REQUEST,
        payload: {
            symbol,
            side,
            size,
            duration,
            invest,
        },
    };
}
export function positionsCloseAllFinished() {
    return {
        type: POSITIONS_CLOSE_ALL_FINISHED,
    };
}
export function positionsCloseAll() {
    return {
        type: POSITIONS_CLOSE_ALL_REQUESTED,
    };
}
export function positionsCloseSingleRequested(positionId) {
    return {
        type: POSITIONS_CLOSE_SINGLE_REQUESTED,
        payload: positionId,
    };
}
export function positionsCloseSingleFinished(positionId) {
    return {
        type: POSITIONS_CLOSE_SINGLE_FINISHED,
        payload: { positionId },
    };
}
export function positionsCloseSingle(positionId) {
    return positionsCloseSingleRequested(positionId);
}
export function setActivePositionId(positionId) {
    return {
        type: POSITIONS_SET_ACTIVE,
        payload: positionId,
    };
}
export function positionsClearActive() {
    return {
        type: POSITIONS_CLEAR_ACTIVE,
    };
}
export function positionClosed(data) {
    return {
        type: POSITION_CLOSED,
        payload: data,
    };
}
export function positionOpened(position) {
    return {
        type: POSITION_OPENED,
        payload: { position },
    };
}
export function togglePositionsDrawer(data) {
    return {
        type: TOGGLE_POSITIONS_DRAWER,
        payload: data,
    };
}
export function setActiveInstrument(symbol) {
    return {
        type: INSTRUMENTS_SET_ACTIVE,
        payload: symbol,
    };
}
export function updateBalanceAdjustmentFromPL(adjustment) {
    return {
        type: BALANCE_ADJUSTMENT_FROM_PL_UPDATED,
        payload: adjustment,
    };
}
export function userLogout() {
    return {
        type: USER_LOGOUT,
    };
}
export function userSaveCredentials(username, remember) {
    return {
        type: USER_SAVE_CREDENTIALS,
        payload: { username, remember },
    };
}
export function userClearCredentials() {
    return {
        type: USER_CLEAR_CREDENTIALS,
    };
}
export function apiDisconnected() {
    return {
        type: API_DISCONNECTED,
    };
}
export function apiError(reason, shouldShowErrorScreen) {
    return {
        type: API_ERROR,
        payload: { reason, shouldShowErrorScreen },
    };
}
export function apiReconnectingState(isReconnecting, seconds) {
    return {
        type: API_RECONNECTING,
        payload: { isReconnecting, seconds },
    };
}
export function reconnect() {
    return {
        type: API_RECONNECT_REQUEST,
    };
}
export function loginSuccess() {
    return {
        type: LOGIN_SUCCESS,
    };
}
export function loginReject(message) {
    return {
        type: LOGIN_REJECT,
        payload: { message },
    };
}
export function userLoginReal() {
    return {
        type: LOGIN_REQUESTED,
    };
}
export function requestOAuthTokens(code, redirectUri) {
    return {
        type: REQUEST_OAUTH_TOKENS,
        payload: { code, redirectUri },
    };
}
export function setOAuthTokens(accessToken, refreshToken) {
    return {
        type: SET_OAUTH_TOKENS,
        payload: {
            accessToken,
            refreshToken,
        },
    };
}
export function refreshTokenAndLogin() {
    return {
        type: REFRESH_TOKEN_AND_LOGIN,
    };
}
export function attemptAutoLogin() {
    return {
        type: AUTO_LOGIN_REQUESTED,
    };
}
export function setLoginStateForAutoLogin(loginState) {
    return {
        type: SET_LOGIN_STATE_FOR_AUTO_LOGIN,
        payload: loginState,
    };
}
export function setChartResolution(resolution) {
    return {
        type: SET_CHART_RESOLUTION,
        payload: {
            resolution,
        },
    };
}
export function updateHistory(data) {
    return {
        type: HISTORY_UPDATED,
        payload: data,
    };
}
export function historyQuotesReceived(data) {
    return {
        type: HISTORY_QUOTES_RECEIVED,
        payload: data,
    };
}
export function showProfitLossSelection(w, h, pX, pY) {
    return {
        type: SHOW_PROFIT_LOSS_SELECTION,
        payload: {
            w,
            h,
            pX,
            pY,
        },
    };
}
export function showInvestSelection(w, h, pX, pY) {
    return {
        type: SHOW_INVEST_SELECTION,
        payload: {
            w,
            h,
            pX,
            pY,
        },
    };
}
export function showDurationSelection(w, h, pX, pY) {
    return {
        type: SHOW_DURATION_SELECTION,
        payload: {
            w,
            h,
            pX,
            pY,
        },
    };
}
export function hideOverlay() {
    return {
        type: HIDE_OVERLAY,
    };
}
export function selectTradeDuration(closePositionAfterSeconds) {
    return {
        type: SELECT_TRADE_DURATION,
        payload: {
            closePositionAfterSeconds,
        },
    };
}
export function setTradeDuration(closePositionAfterSeconds) {
    return {
        type: SET_TRADE_DURATION,
        payload: {
            closePositionAfterSeconds,
        },
    };
}
export function selectTradeProfitLoss(profitLoss) {
    return {
        type: SELECT_TRADE_PROFIT_LOSS,
        payload: {
            profitLoss,
        },
    };
}
export function setTradeProfitLoss(profitLoss) {
    return {
        type: SET_TRADE_PROFIT_LOSS,
        payload: {
            profitLoss,
        },
    };
}
export function saveInstrumentProfitLoss(profitLoss, instrumentSymbol) {
    return {
        type: SAVE_INSTRUMENT_PROFIT_LOSS,
        payload: {
            profitLoss,
            instrumentSymbol,
        },
    };
}
export function selectTradeInvest(invest) {
    return {
        type: SELECT_TRADE_INVEST,
        payload: {
            invest,
        },
    };
}
export function getLeaderboardRankings() {
    return {
        type: LEADERBOARD_RANKING_REQUEST,
    };
}
export function refreshLeaderboardRankings() {
    return {
        type: LEADERBOARD_RANKING_REFRESH_REQUEST,
    };
}
export function leaderboardRankingSuccess(ranks) {
    return {
        type: LEADERBOARD_RANKING_SUCCESS,
        payload: { ranks },
    };
}
export function leaderboardRankingReject(error) {
    return {
        type: LEADERBOARD_RANKING_REJECT,
        payload: { error },
    };
}
export function getAccountHistory() {
    return {
        type: ACCOUNT_HISTORY_REQUEST,
    };
}
export function setAccountHistory(accountHistory) {
    return {
        type: SET_ACCOUNT_HISTORY,
        payload: { accountHistory },
    };
}
export function requestHistoryQuotes(symbol, start, end, resolution) {
    return {
        type: apiActions.SEND,
        payload: ApiMessages.requestHistoryQuotes(symbol, start, end, resolution),
    };
}
export function setHistoryQuotesLoading() {
    return {
        type: HISTORY_QUOTES_LOADING,
    };
}
export function setCandlesData(data) {
    return {
        type: SET_CANDLES_DATA,
        payload: { data },
    };
}
export function requestSetDisplayName(name) {
    return {
        type: apiActions.SEND,
        payload: ApiMessages.setDisplayName(name),
    };
}
export function requestPositionCloseAll() {
    return {
        type: apiActions.SEND,
        payload: ApiMessages.requestPositionCloseAll(),
    };
}
export function logoff(options) {
    const { userRequested = false, withOAuth = false } = options || {};
    return {
        type: LOGOFF,
        payload: { userRequested, withOAuth },
    };
}
export function loggedOff() {
    return {
        type: LOGGED_OFF,
    };
}
export function sendPong() {
    return {
        type: apiActions.SEND,
        payload: ApiMessages.pong(),
    };
}
export function setActiveSymbol(symbol) {
    return {
        type: SET_ACTIVE_SYMBOL,
        payload: { symbol },
    };
}
export function requestPositionCloseSingle(positionId) {
    return {
        type: apiActions.SEND,
        payload: ApiMessages.requestPositionCloseSingle(positionId),
    };
}
export function requestOpenPositionsList() {
    return {
        type: apiActions.SEND,
        payload: ApiMessages.requestOpenPositions(),
    };
}
export function requestOpenPosition(requestId, symbol, side, size, duration, invest) {
    return {
        type: apiActions.SEND,
        payload: ApiMessages.openPosition(requestId, symbol, side, size, duration, invest),
    };
}
export function requestSetActiveSymbol(symbol) {
    return {
        type: apiActions.SEND,
        payload: ApiMessages.setActiveSymbol(symbol),
    };
}
export function requestGetAccountHistory() {
    return {
        type: apiActions.SEND,
        payload: ApiMessages.getAccountHistory(),
    };
}
export function positionClosingRevert(positionId) {
    return {
        type: POSITION_CLOSING_REVERT,
        payload: { positionId },
    };
}
export function toggleDevMenu() {
    return {
        type: TOGGLE_DEV_MENU,
    };
}
export function setDevMenuSettings(devMenuSettings) {
    return {
        type: SET_DEV_MENU_SETTINGS,
        payload: { devMenuSettings },
    };
}
export function receiveInstruments(instruments, timezone, defaultInstrument) {
    return {
        type: INSTRUMENTS_RECEIVED,
        payload: { instruments, timezone, defaultInstrument },
    };
}
export function requestInstruments() {
    return {
        type: REQUEST_INSTRUMENTS,
    };
}
export function positionStatus(data) {
    return {
        type: POSITION_STATUS,
        payload: data,
    };
}
export function receivedOpenPositionsList(openPositions) {
    return {
        type: RECEIVED_OPENED_POSITIONS_LIST,
        payload: openPositions,
    };
}
export function openPositionsListUpdated(openPositions) {
    return {
        type: OPEN_POSITIONS_LIST_UPDATE,
        payload: { openPositions },
    };
}
export function setSoundbankLoadingPercentage(percentage) {
    return {
        type: SET_SOUNDBANK_LOADING_PERCENTAGE,
        payload: { percentage },
    };
}
export function setSoundbankLoading(loading) {
    return {
        type: SET_SOUNDBANK_LOADING,
        payload: { loading },
    };
}
export function setIsOnline(isOnline) {
    return {
        type: SET_IS_ONLINE,
        payload: { isOnline },
    };
}
export function setSoundstyle(soundstyle) {
    return {
        type: SET_SOUNDSTYLE,
        payload: { soundstyle },
    };
}
export function setDontShowInstallCTA() {
    return {
        type: SET_DONT_SHOW_INSTALL_CTA,
    };
}
export function setDontShowChromeInstallCTA() {
    return {
        type: SET_DONT_SHOW_CHROME_INSTALL_CTA,
    };
}
export function setInstallCTAHiddenAt(hiddenAt) {
    return {
        type: SET_INSTALL_CTA_HIDDEN_AT,
        payload: { hiddenAt },
    };
}
export function setPWAInstallPrompt(installPrompt) {
    return {
        type: SET_PWA_INSTALL_PROMPT,
        payload: { installPrompt },
    };
}
export function resetState() {
    return {
        type: RESET_ALL,
    };
}
export function receivedFeaturedFlags(flags) {
    return {
        type: RECEIVED_FEATURE_FLAGS,
        payload: { flags },
    };
}
export function socketClosed() {
    return {
        type: SOCKET_CLOSED,
    };
}
export function socketError(code, reason) {
    return {
        type: SOCKET_ERROR,
        payload: { code, reason },
    };
}
export function getCoinsBalance() {
    return {
        type: apiActions.SEND,
        payload: ApiMessages.getCoinsBalance(),
    };
}
export function setCoinsBalance(balance) {
    return {
        type: COINS_BALANCE_UPDATED,
        payload: { balance },
    };
}
export function showSideDrawer() {
    return {
        type: SET_SIDE_DRAWER_SHOWN,
        payload: true,
    };
}
export function hideSideDrawer() {
    return {
        type: SET_SIDE_DRAWER_SHOWN,
        payload: false,
    };
}
export function updateUser(params) {
    return {
        type: UPDATE_USER_REQUESTED,
        payload: params,
    };
}
export function updateUserReject(reason) {
    return {
        type: UPDATE_USER_REJECT,
        payload: { reason },
    };
}
export function updateUserSuccess(communicationLanguage) {
    return {
        type: UPDATE_USER_SUCCESS,
        payload: { communicationLanguage },
    };
}
export function fetchMessages() {
    return {
        type: FETCH_MESSAGES_REQUESTED,
    };
}
export function fetchMessagesSuccess(messages, pagination) {
    return {
        type: FETCH_MESSAGES_SUCCESS,
        payload: { messages, pagination },
    };
}
export function fetchMessagesReject(reason) {
    return {
        type: FETCH_MESSAGES_REJECT,
        payload: { reason },
    };
}
export function fetchMessagesNextPage() {
    return {
        type: FETCH_MESSAGES_NEXT_PAGE_REQUESTED,
    };
}
export function fetchMessagesNextPageSuccess(newMessages, pagination) {
    return {
        type: FETCH_MESSAGES_NEXT_PAGE_SUCCESS,
        payload: { newMessages, pagination },
    };
}
export function fetchMessagesNextPageReject(reason) {
    return {
        type: FETCH_MESSAGES_NEXT_PAGE_REJECT,
        payload: { reason },
    };
}
export function fetchMessagesUnreadCount() {
    return {
        type: FETCH_MESSAGES_UNREAD_COUNT_REQUESTED,
    };
}
export function fetchMessagesUnreadCountSuccess(unreadCount) {
    return {
        type: FETCH_MESSAGES_UNREAD_COUNT_SUCCESS,
        payload: { unreadCount },
    };
}
export function fetchMessagesUnreadCountReject(reason) {
    return {
        type: FETCH_MESSAGES_UNREAD_COUNT_REJECT,
        payload: { reason },
    };
}
export function fetchCoinsExchangeRate() {
    return {
        type: FETCH_COINS_EXCHANGE_RATE_REQUESTED,
    };
}
export function fetchCoinsExchangeRateReject(reason) {
    return {
        type: FETCH_COINS_EXCHANGE_RATE_REJECT,
        payload: { reason },
    };
}
export function fetchCoinsExchangeRateSuccess(rate, effectiveRate, starsBonus) {
    return {
        type: FETCH_COINS_EXCHANGE_RATE_SUCCESS,
        payload: { rate, effectiveRate, starsBonus },
    };
}
export function requestJoinCompetition(nickname) {
    return {
        type: JOIN_COMPETITION_REQUESTED,
        payload: { nickname },
    };
}
export function joinCompetitionSuccess(competition) {
    return {
        type: JOIN_COMPETITION_SUCCESS,
        payload: { competition },
    };
}
export function joinCompetitionReject(error) {
    return {
        type: JOIN_COMPETITION_REJECT,
        payload: { error },
    };
}
export function setLeaderboardUserDetails(rank, nickname, points, change) {
    return {
        type: SET_LEADERBOARD_USER_DETAILS,
        payload: { rank, nickname, points, change },
    };
}
export function requestCurrentCompetition() {
    return {
        type: CURRENT_COMPETITION_REQUESTED,
    };
}
export function currentCompetitionSuccess(data) {
    return {
        type: CURRENT_COMPETITION_SUCCESS,
        payload: { data },
    };
}
export function currentCompetitionReject(error) {
    return {
        type: CURRENT_COMPETITION_REJECT,
        payload: { error },
    };
}
export function receivedCompetitionRank(rank, previousRank, nickname, points) {
    return {
        type: RECEIVED_COMPETITION_RANK_UPDATE,
        payload: { rank, previousRank, nickname, points },
    };
}
export function enableAutoLogin() {
    return {
        type: ENABLE_AUTO_LOGIN,
    };
}
export function disableAutoLogin() {
    return {
        type: DISABLE_AUTO_LOGIN,
    };
}
export function setReleaseVersion(version) {
    return {
        type: SET_RELEASE_VERSION,
        payload: {
            version,
        },
    };
}
export function getTrendQuotes() {
    return {
        type: apiActions.SEND,
        payload: ApiMessages.getTrendQuotes(),
    };
}
export function receivedTrendQuotes(trends) {
    return {
        type: RECEIVED_TREND_QUOTES,
        payload: { trends },
    };
}
export function setLowMarginModalShown() {
    return {
        type: SET_LOW_MARGIN_MODAL_SHOWN,
    };
}
export function loginLoaderShown() {
    return {
        type: LOGIN_LOADER_SHOWN,
    };
}
export function loginLoaderHidden() {
    return {
        type: LOGIN_LOADER_HIDDEN,
    };
}
export function setLoggingIn() {
    return {
        type: SET_LOGGING_IN,
    };
}
export function unsetLoggingIn() {
    return {
        type: UNSET_LOGGING_IN,
    };
}
export function setStopOutOccurred() {
    return {
        type: SET_STOP_OUT_OCCURRED,
    };
}
export function clearStopOutOccurred() {
    return {
        type: CLEAR_STOP_OUT_OCCURRED,
    };
}
export function setMaintenanceMode() {
    return {
        type: SET_MAINTENANCE_MODE,
    };
}
export function setInstrumentsValidTradingIntervals(instrumentsValidTradingIntervals) {
    return {
        type: SET_INSTRUMENTS_VALID_TRADING_INTERVALS,
        payload: { instrumentsValidTradingIntervals },
    };
}
export function setPositionsTimeLeft(positionsTimeLeft) {
    return {
        type: SET_POSITIONS_TIME_LEFT,
        payload: { positionsTimeLeft },
    };
}
export function setRightSideWidget(rightSideWidget) {
    return {
        type: SET_RIGHT_SIDE_WIDGET,
        payload: { rightSideWidget },
    };
}
export function showInsufficientFundsModal(requiredAmount) {
    return {
        type: SHOW_INSUFFICIENT_FUNDS_MODAL,
        payload: { requiredAmount },
    };
}
export function hideInsufficientFundsModal() {
    return {
        type: HIDE_INSUFFICIENT_FUNDS_MODAL,
    };
}
export function receivedInstrumentTrendMessage(data) {
    return {
        type: RECEIVED_INSTRUMENT_TREND_MESSAGE,
        payload: { symbol: data.symbol, trend: data.trend },
    };
}
export function showInstrumentTrendExplainerModal() {
    return {
        type: SHOW_INSTRUMENT_TREND_EXPLAINER_MODAL,
    };
}
export function hideInstrumentTrendExplainerModal() {
    return {
        type: HIDE_INSTRUMENT_TREND_EXPLAINER_MODAL,
    };
}
export function setInstrumentToPreselect(symbol) {
    return {
        type: SET_INSTRUMENT_TO_PRESELECT,
        payload: { symbol },
    };
}
export function receivedTimeoutError(message) {
    return {
        type: RECEIVED_TIMEOUT_ERROR,
        payload: { message },
    };
}
export function updateUserLoyaltyLevel(loyaltyLevel) {
    return {
        type: UPDATE_USER_LOYALTY_LEVEL,
        payload: { loyaltyLevel },
    };
}
export function receivedTradingOptions(tradingOptions) {
    return {
        type: RECEIVED_TRADING_OPTIONS,
        payload: { tradingOptions },
    };
}
export function setOrders(orders) {
    return {
        type: SET_ORDERS,
        payload: { orders },
    };
}
export function updateOrder(order) {
    return {
        type: UPDATE_ORDER,
        payload: { order },
    };
}
export function addOrder(order) {
    return {
        type: ADD_ORDER,
        payload: { order },
    };
}
export function addPriceAlert(priceAlertMessage) {
    return {
        type: ADD_PRICE_ALERT,
        payload: { priceAlertMessage },
    };
}
export function removePriceAlertAtIndex(index) {
    return {
        type: REMOVE_PRICE_ALERT_AT_INDEX,
        payload: { index },
    };
}
export function setIsSidebarCollapsed(isCollapsed) {
    return {
        type: SET_IS_SIDEBAR_COLLAPSED,
        payload: { isCollapsed },
    };
}
export function subscribeToQuotesForSymbols(symbols, format = 'protobuf') {
    return {
        type: apiActions.SEND,
        payload: ApiMessages.subscribeToQuotes(symbols, format),
    };
}
export function subscribeToQuotesBatchForSymbols(symbols, format = 'protobuf', intervalMs) {
    return {
        type: apiActions.SEND,
        payload: ApiMessages.subscribeToBatchQuotes(symbols, format, intervalMs),
    };
}
export function addActiveInstrumentSubscriptionId(subcriptionId) {
    return {
        type: ADD_ACTIVE_INSTRUMENT_SUBSCRIPTION_ID,
        payload: { subcriptionId },
    };
}
export function addInstrumentsBatchSubscriptionId(subcriptionId) {
    return {
        type: ADD_INSTRUMENTS_BATCH_SUBSCRIPTION_ID,
        payload: { subcriptionId },
    };
}
export function unsubscribeFromQuotes(subscriptionId) {
    return {
        type: apiActions.SEND,
        payload: ApiMessages.unsubscribeFromQuotes(subscriptionId),
    };
}
export function unsubscribeFromBatchQuotes(subscriptionId) {
    return {
        type: apiActions.SEND,
        payload: ApiMessages.unsubscribeFromBatchQuotes(subscriptionId),
    };
}
export function enableCrosshairPriceSelection() {
    return {
        type: ENABLE_CROSSHAIR_PRICE_SELECTION,
    };
}
export function disableCrosshairPriceSelection() {
    return {
        type: DISABLE_CROSSHAIR_PRICE_SELECTION,
    };
}
export function setLiveCrosshairPrice(price) {
    return {
        type: SET_LIVE_CROSSHAIR_PRICE,
        payload: { price },
    };
}
export function selectCurrentCrosshairPrice() {
    return {
        type: SELECT_CURRENT_CROSSHAIR_PRICE,
    };
}
export function receivedMarginCallLevel(marginCallLevel) {
    return {
        type: RECEIVED_MARGIN_CALL_LEVEL,
        payload: { marginCallLevel },
    };
}
export function setConnectionReady(ready) {
    return {
        type: SET_CONNECTION_READY,
        payload: { ready },
    };
}
export function setGreyLabelLogo(logos) {
    return {
        type: FETCH_GREY_LABEL_LOGO_SUCCESS,
        payload: { logos },
    };
}
export function requestGreyLabelLogo() {
    return {
        type: FETCH_GREY_LABEL_LOGO_REQUESTED,
    };
}
export function handleGreyLabelLogoError() {
    return {
        type: FETCH_GREY_LABEL_LOGO_REJECTED,
    };
}
export function hideDepositWithdrawalModal() {
    return {
        type: HIDE_DEPOSIT_WITHDRAWAL_MODAL,
    };
}
export function showDepositWithdrawalModal() {
    return {
        type: SHOW_DEPOSIT_WITHDRAWAL_MODAL,
    };
}
export function hideMarketClosedErrorModal() {
    return {
        type: HIDE_MARKET_CLOSED_ERROR_MODAL,
    };
}
export function showMarketClosedErrorModal() {
    return {
        type: SHOW_MARKET_CLOSED_ERROR_MODAL,
    };
}
