import { isNumber, isString } from 'lodash-es';
import { PositionSide } from "../reducers/positions/types";
import { store } from '../index';
import quotesHelpers from './quotesHelpers';
import { isNumeric, isSet } from './helpers';
function pad(num) {
    return ('0' + num).slice(-2);
}
const calcProfitLoss = (position) => {
    const { instruments } = store.getState();
    const instrument = instruments[position.symbol];
    const contractSize = instrument && instrument.contractSize ? instrument.contractSize : 1;
    const direction = position.side === PositionSide.BUY ? 1 : -1;
    let quoteValue = position.side === PositionSide.BUY
        // @ts-ignore
        ? quotesHelpers.getLastQuoteForSymbol(position.symbol).bid
        // @ts-ignore
        : quotesHelpers.getLastQuoteForSymbol(position.symbol).ask;
    let openPrice = position.openPrice;
    // @ts-ignore
    const delta = quoteValue - openPrice;
    // @ts-ignore
    const loot = position.size / 100 * contractSize;
    return direction * delta * loot;
};
const calcStopLoss = (position) => {
    const { instruments } = store.getState();
    const instrument = instruments[position.symbol];
    const precision = instrument ? instrument.precision : 1;
    const contractSize = instrument && instrument.contractSize ? instrument.contractSize : 1;
    let quoteValue = position.side === PositionSide.BUY
        // @ts-ignore
        ? quotesHelpers.getLastQuoteForSymbol(position.symbol).bid
        // @ts-ignore
        : quotesHelpers.getLastQuoteForSymbol(position.symbol).ask;
    const loot = position.size * contractSize;
    const pointDiff = position.invest / loot;
    const absValue = position.side === PositionSide.BUY ? quoteValue - pointDiff : quoteValue + pointDiff;
    return absValue;
};
const sanitizeSinglePosition = (data) => {
    const positions = store.getState().positions;
    let newPositionData = {
        positionId: null,
        profitLoss: 0,
        status: null,
        size: null,
        side: null,
        stopLoss: null,
        takeProfit: null,
        invest: null,
        closePositionAfterSeconds: null,
        openPrice: null,
        closePrice: null,
        openedAt: null,
        closedAt: null,
        closedPL: null,
        closeReason: null,
        symbol: null,
        margin: null,
        platform: null,
        reason: null,
    };
    if (positions[data.positionId]) {
        // @ts-ignore
        newPositionData = Object.assign(Object.assign({}, newPositionData), positions[data.positionId]);
    }
    newPositionData.symbol = isString(data.symbol) ? data.symbol : newPositionData.symbol;
    newPositionData.positionId = isNumber(data.positionId) ? data.positionId : newPositionData.positionId;
    newPositionData.profitLoss = isNumeric(data.profitLoss)
        ? Number(data.profitLoss)
        : newPositionData.profitLoss;
    newPositionData.status = isString(data.status) ? data.status : newPositionData.status;
    newPositionData.size = isNumeric(data.size) ? Number(data.size) : newPositionData.size;
    newPositionData.invest = isNumeric(data.invest) ? Number(data.invest) : newPositionData.invest;
    newPositionData.side = isString(data.side) ? data.side : newPositionData.side;
    newPositionData.closePositionAfterSeconds = isNumber(data.closePositionAfterSeconds) ? data.closePositionAfterSeconds : newPositionData.closePositionAfterSeconds;
    newPositionData.openPrice = isNumeric(data.openPrice) ? Number(data.openPrice) : newPositionData.openPrice;
    newPositionData.closePrice = isNumeric(data.closePrice) ? Number(data.closePrice) : newPositionData.closePrice;
    newPositionData.closedPL = isNumeric(data.closedPL) ? Number(data.closedPL) : newPositionData.closedPL;
    newPositionData.closeReason = isString(data.closeReason) ? data.closeReason : newPositionData.closeReason;
    newPositionData.platform = isString(data.platform) ? data.platform : newPositionData.platform;
    newPositionData.margin = isNumeric(data.margin) ? Number(data.margin) : newPositionData.margin;
    newPositionData.reason = isString(data.reason) ? data.reason : newPositionData.reason;
    newPositionData.openedAt = isSet(data.openedAt) ? data.openedAt : newPositionData.openedAt;
    newPositionData.closedAt = isSet(data.closedAt) ? data.closedAt : newPositionData.closedAt;
    if (data.stopLoss === null) {
        newPositionData.stopLoss = null;
    }
    else if (isNumeric(data.stopLoss)) {
        newPositionData.stopLoss = Number(data.stopLoss);
    }
    if (data.takeProfit === null) {
        newPositionData.takeProfit = null;
    }
    else if (isNumeric(data.takeProfit)) {
        newPositionData.takeProfit = Number(data.takeProfit);
    }
    return newPositionData;
};
const getTimeLeft = (position) => {
    const { openedAt, closePositionAfterSeconds } = position;
    if (isSet(openedAt) && closePositionAfterSeconds === null) {
        return {
            timeLeft: Infinity,
            timeLeftPercentage: 0,
        };
    }
    if (isSet(openedAt) && isSet(closePositionAfterSeconds)) {
        const openTimeMs = new Date(openedAt).getTime() / 1000;
        const currentTimeMs = Date.now() / 1000;
        const timeLeft = closePositionAfterSeconds - (currentTimeMs - openTimeMs);
        const timeLeftPercentage = ((closePositionAfterSeconds - timeLeft) / closePositionAfterSeconds) * 100;
        return {
            timeLeft: Math.max(0, timeLeft),
            timeLeftPercentage: Math.min(Math.max(timeLeftPercentage, 0), 100),
        };
    }
    else {
        throw Error('openedAt & closePositionAfterSeconds need to be defined for timeLeft calculation');
    }
};
export default {
    calcProfitLoss,
    calcStopLoss,
    sanitizeSinglePosition,
    getTimeLeft,
};
