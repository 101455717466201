import { ADD_ACTIVE_INSTRUMENT_SUBSCRIPTION_ID, ADD_INSTRUMENTS_BATCH_SUBSCRIPTION_ID, INSTRUMENTS_SET_ACTIVE, INSTRUMENTS_UPDATED } from '../../actions';
export default function instrumentsReducer(state, action) {
    switch (action.type) {
        case INSTRUMENTS_UPDATED:
            return Object.assign(Object.assign({}, state), { instruments: action.payload.instruments, instrumentsTimezone: action.payload.timezone });
        case INSTRUMENTS_SET_ACTIVE: {
            const activeInstrument = state.instruments[action.payload];
            if (!activeInstrument) {
                throw new Error('Failed to set active instrument because it cannot be found by symbol ' + action.payload);
            }
            const activeInstrumentQuotes = state.quotes[activeInstrument.symbol];
            return Object.assign(Object.assign({}, state), { activeInstrument, activeInstrumentSymbol: action.payload, tradingEnabled: !!activeInstrumentQuotes });
        }
        case ADD_ACTIVE_INSTRUMENT_SUBSCRIPTION_ID: {
            if (!action.payload.subcriptionId) {
                throw new Error("You must pass an activeInstrumentSubscriptionId");
            }
            return Object.assign(Object.assign({}, state), { activeInstrumentSubscriptionId: action.payload.subcriptionId });
        }
        case ADD_INSTRUMENTS_BATCH_SUBSCRIPTION_ID: {
            if (!action.payload.subcriptionId) {
                throw new Error("You must pass a instrumentBatchSubscriptionId");
            }
            return Object.assign(Object.assign({}, state), { instrumentsBatchSubscriptionId: action.payload.subcriptionId });
        }
        default:
            return state;
    }
}
