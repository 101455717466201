import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import MobileScreenLayout from '../../layout/MobileScreenLayout/MobileScreenLayout';
import _t from 'counterpart';
import { useGoBackTo } from '../../hooks/useGoBackTo';
import InstrumentsList from 'components/InstrumentsList/InstrumentsList';
import { useAppSelector } from '../../storeHooks';
const MobileInstrumentsScreen = () => {
    const goBack = useGoBackTo('/trading');
    const instruments = useAppSelector((state) => state.instruments);
    const instrumentsArray = useMemo(() => Object.values(instruments), [instruments]);
    return (_jsx(MobileScreenLayout, Object.assign({ title: _t('main.instruments'), onBackClick: goBack }, { children: _jsx(InstrumentsList, { instruments: instrumentsArray, onInstrumentClick: goBack, showSearch: true }) })));
};
export default React.memo(MobileInstrumentsScreen);
