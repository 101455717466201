import * as z from 'zod';
export var OrderType;
(function (OrderType) {
    OrderType["MARKET"] = "market";
    OrderType["LIMIT"] = "limit";
    OrderType["STOP"] = "stop";
})(OrderType || (OrderType = {}));
export var OrderSide;
(function (OrderSide) {
    OrderSide["BUY"] = "buy";
    OrderSide["SELL"] = "sell";
})(OrderSide || (OrderSide = {}));
export var OrderReason;
(function (OrderReason) {
    OrderReason["USER_TRADING"] = "user_trading";
    OrderReason["TIME_EXPIRED"] = "time_expired";
    OrderReason["INVEST_EXCEEDED"] = "invest_exceeded";
    OrderReason["STOP_OUT"] = "stop_out";
    OrderReason["COPY_TRADING"] = "copytrading";
    OrderReason["STOP_LOSS"] = "stop_loss";
    OrderReason["TAKE_PROFIT"] = "take_profit";
    OrderReason["NONE"] = "";
})(OrderReason || (OrderReason = {}));
export var OrderStatus;
(function (OrderStatus) {
    OrderStatus["NEW"] = "new";
    OrderStatus["ACCEPTED"] = "accepted";
    OrderStatus["PENDING"] = "pending";
    OrderStatus["FILLED"] = "filled";
    OrderStatus["REJECTED"] = "rejected";
    OrderStatus["TIMEOUT"] = "timeout";
    OrderStatus["CANCELLED"] = "cancelled";
})(OrderStatus || (OrderStatus = {}));
export var OrderDuration;
(function (OrderDuration) {
    OrderDuration["GTC"] = "GTC";
    OrderDuration["GTD"] = "GTD";
    OrderDuration["GFD"] = "GFD";
    OrderDuration["FOK"] = "FOK";
    OrderDuration["IOC"] = "IOC";
})(OrderDuration || (OrderDuration = {}));
export const OrderDurationTitle = {
    [OrderDuration.GTC]: "Good Till Cancelled",
    [OrderDuration.GTD]: "Good Till Date",
    [OrderDuration.GFD]: "Good For Day",
    [OrderDuration.FOK]: "Fill Or Kill",
    [OrderDuration.IOC]: "Immediate Or Cancel",
};
export const orderSchema = z.object({
    id: z.number(),
    type: z.nativeEnum(OrderType),
    symbol: z.string(),
    side: z.nativeEnum(OrderSide),
    reason: z.nativeEnum(OrderReason),
    orderDuration: z.nativeEnum(OrderDuration),
    positionId: z.number().nullable(),
    limitPrice: z.coerce.number().nullable(),
    stopPrice: z.coerce.number().nullable(),
    stopLoss: z.coerce.number().nullish(),
    takeProfit: z.coerce.number().nullish(),
    size: z.coerce.number(),
    filledQuantity: z.coerce.number().nullable(),
    placedAt: z.coerce.date(),
    executedAt: z.coerce.date().nullable(),
    averagePrice: z.coerce.number().nullable(),
    status: z.nativeEnum(OrderStatus),
});
export const ordersSchema = z.array(orderSchema);
