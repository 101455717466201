import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import styles from './LoyaltyTransitionStat.module.scss';
import classNames from 'classnames';
import { LoyaltyLevelName } from 'wintrado-api';
import { useAppSelector } from '../../../storeHooks';
import _t from 'counterpart';
import { isSet, numberToCompactNotation } from '../../../helpers';
const LoyaltyTransitionStat = () => {
    const { currentLevelName, coinsUntilNextLevel, totalCoinsMonth } = useAppSelector((state) => state.user.loyaltyLevel);
    const transitionCrownIcon = useMemo(() => {
        return require(`../../icons/${currentLevelName}-transition-crown.svg?url`);
    }, [currentLevelName]);
    const coinsForNextLevel = useMemo(() => {
        if (coinsUntilNextLevel !== null) {
            return totalCoinsMonth + coinsUntilNextLevel;
        }
        else {
            return null;
        }
    }, [totalCoinsMonth, coinsUntilNextLevel]);
    return (_jsxs("div", Object.assign({ className: classNames(styles.container, {
            [styles.containerBronze]: currentLevelName === LoyaltyLevelName.BRONZE,
            [styles.containerSilver]: currentLevelName === LoyaltyLevelName.SILVER,
            [styles.containerGold]: currentLevelName === LoyaltyLevelName.GOLD,
            [styles.containerPlatinum]: currentLevelName === LoyaltyLevelName.PLATINUM,
            [styles.containerDiamond]: currentLevelName === LoyaltyLevelName.DIAMOND,
        }) }, { children: [_jsx("div", Object.assign({ className: styles.crownWrapper }, { children: _jsx("img", { src: transitionCrownIcon, alt: currentLevelName, className: styles.crown }) })), _jsxs("div", Object.assign({ className: styles.titleSubtitleWrapper }, { children: [_jsx("h6", Object.assign({ className: styles.title }, { children: _t.translate(`loyalty.transitions.title.${currentLevelName}`) })), _jsx("span", Object.assign({ className: styles.subtitle }, { children: _t.translate(`loyalty.transitions.subtitle.${currentLevelName}`, { coinsUntilNextLevel }) }))] })), _jsxs("div", Object.assign({ className: styles.progressStat }, { children: [_jsxs("div", Object.assign({ className: styles.progressIconAndLabelWrapper }, { children: [_jsx("img", { src: require(`../../icons/${currentLevelName}-running-man.svg?url`), alt: "running-man" }), _jsxs("div", Object.assign({ className: styles.progressLabelWrapper }, { children: [_jsx("span", Object.assign({ className: classNames(styles.progressLabel, styles.progressLabelAccent) }, { children: numberToCompactNotation(totalCoinsMonth) })), isSet(coinsForNextLevel) && (_jsx("span", Object.assign({ className: styles.progressLabel }, { children: `/${numberToCompactNotation(coinsForNextLevel)}` })))] }))] })), _jsx("div", Object.assign({ className: styles.progressBarTrack }, { children: _jsx("div", { className: styles.progressBar, style: { width: coinsForNextLevel ? `${(totalCoinsMonth / coinsForNextLevel) * 100}%` : '100%' } }) }))] }))] })));
};
export default React.memo(LoyaltyTransitionStat);
