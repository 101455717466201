import { addActiveInstrumentSubscriptionId, addInstrumentsBatchSubscriptionId, addOrder, addPriceAlert, apiDisconnected, apiError, displayError, historyQuotesReceived, loginReject, loginSuccess, positionClosed, positionOpened, positionStatus, receivedCompetitionRank, receivedFeaturedFlags, receivedInstrumentTrendMessage, receivedMarginCallLevel, receivedOpenPositionsList, receivedTimeoutError, receivedTradingOptions, receivedTrendQuotes, receivePrimeQuotes, receiveQuote, receiveQuotes, sendPong, setAccountHistory, setCoinsBalance, setOrders, updateBalance, updateOrder, updateUserLoyaltyLevel } from "./actions";
import { openedPositionSchema, } from './reducers/positions/types';
import { orderSchema, ordersSchema, } from './reducers/orders/types';
import * as Sentry from '@sentry/react';
export const ApiResponseHandlers = {
    Ping: () => {
        return sendPong();
    },
    FeatureFlags: (data) => {
        return receivedFeaturedFlags(data.flags);
    },
    AccountBalance: (data) => {
        return updateBalance({
            currency: data.currency,
            balance: Number(data.balance),
            credit: Number(data.credit)
        });
    },
    MostVolatileInstrument: (data) => {
        // TODO nothing?
        return {};
    },
    HistoryQuotesResponse: (data) => {
        return historyQuotesReceived(data);
    },
    TradingOptions: (data) => {
        return receivedTradingOptions(data);
    },
    DateTime: () => ({}),
    Error: (data) => {
        console.error(`An API Error occured. Reason: ${data.reason}`);
        return apiError(data.reason);
    },
    error: (data) => {
        console.error(`An API Error occured. Reason: ${data.reason}`);
        return apiError(data.reason);
    },
    LoginSuccess: (data) => {
        return loginSuccess();
    },
    LoginReject: (data) => {
        return loginReject(data.reason);
    },
    Logoff: (data) => {
        return apiDisconnected();
    },
    PositionCloseReject: (data) => {
        return displayError("POSITION_CLOSE_REJECTED", data.reason, 5000);
    },
    PositionOpened: (data) => {
        try {
            return positionOpened(openedPositionSchema.parse(data));
        }
        catch (e) {
            Sentry.captureException(e);
            console.error("Error parsing PositionOpened", e);
            return {};
        }
    },
    PositionClosed: (data) => {
        return positionClosed(data);
    },
    PositionStatus: (data) => {
        return positionStatus(data);
    },
    PositionUpdated: (data) => {
        // takeProfit and stopLoss are passed as empty strings (as Protobuf knows no null) when they are unset
        // and must be converted back to null first.
        if (data.stopLoss === "") {
            data.stopLoss = null;
        }
        if (data.takeProfit === "") {
            data.takeProfit = null;
        }
        return positionStatus(data);
    },
    OpenPositionsListResponse: (data) => {
        return receivedOpenPositionsList(data.openPositions);
    },
    Quote: (data) => {
        return receiveQuote(data);
    },
    QuotesBatch: (data) => {
        return receiveQuotes(data.quotes);
    },
    PrimeQuotes: (data) => {
        return receivePrimeQuotes(data.quotes);
    },
    AccountHistoryResponse: (data) => {
        const trades = data.trades.reverse();
        const newData = Object.assign(Object.assign({}, data), { trades });
        return setAccountHistory(newData);
    },
    CoinsBalance: (data) => {
        return setCoinsBalance(data.balance);
    },
    CompetitionRank: (data) => {
        return receivedCompetitionRank(data.rank, data.previousRank, data.nickname, data.points);
    },
    TrendQuotesResponse: ({ trends }) => {
        return receivedTrendQuotes(trends);
    },
    TrendMessage: (data) => {
        return receivedInstrumentTrendMessage(data);
    },
    TimeoutError: (data) => {
        return receivedTimeoutError(data);
    },
    LoyaltyLevel: (data) => {
        return updateUserLoyaltyLevel(data);
    },
    PendingOrdersListResponse: (data) => {
        try {
            return setOrders(ordersSchema.parse(data.orders));
        }
        catch (e) {
            Sentry.captureException(e);
            console.error("Error parsing PendingOrdersListResponse", e);
            return {};
        }
    },
    OrderCreated: (data) => {
        try {
            return addOrder(orderSchema.parse(data.order));
        }
        catch (e) {
            Sentry.captureException(e);
            console.error("Error parsing PendingOrderCreated", e);
            return {};
        }
    },
    OrderUpdated: (data) => {
        try {
            return updateOrder(orderSchema.parse(data.order));
        }
        catch (e) {
            Sentry.captureException(e);
            console.error("Error parsing PendingOrderUpdated", e);
            return {};
        }
    },
    MarketAlert: (data) => {
        return addPriceAlert(Object.assign({ id: `${data.payload.symbol}-${data.payload.price}-${new Date().getTime()}` }, data.payload));
    },
    SubscribeQuotesResponse: (data) => {
        return addActiveInstrumentSubscriptionId(data.subscriptionId);
    },
    SubscribeQuotesBatchResponse: (data) => {
        return addInstrumentsBatchSubscriptionId(data.subscriptionId);
    },
    MarginCallLevel: (data) => {
        return receivedMarginCallLevel(data.marginCallLevel);
    }
};
