import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import styles from './LostConnectionDialog.module.scss';
import { useAppDispatch, useAppSelector } from '../../../storeHooks';
import { actions } from 'wintrado-api';
import _t from 'counterpart';
import Button from 'components/Button/Button';
const ConnectionStatus = () => {
    const [currentDelay, setCurrentDelay] = useState(0);
    const dispatch = useAppDispatch();
    const reconnectingIn = useAppSelector((state) => state.connection.reconnectingIn);
    const isReconnecting = useAppSelector((state) => state.connection.isReconnecting);
    const apiError = useAppSelector((state) => state.connection.apiError);
    useEffect(() => {
        if (reconnectingIn !== currentDelay) {
            setCurrentDelay(reconnectingIn);
        }
        // Only dependency must be reconnectingIn, on change it will restart the timer which changes every second
        // If currentDelay was in deps it would always set it last backoff value
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reconnectingIn]);
    useEffect(() => {
        const interval = setInterval(() => {
            const nextTime = currentDelay - 1;
            setCurrentDelay(nextTime);
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [currentDelay]);
    const tryReconnecting = () => {
        dispatch(actions.reconnect());
    };
    return (_jsx("div", Object.assign({ className: styles.container }, { children: _jsxs("div", Object.assign({ className: styles.inner }, { children: [_jsxs("div", Object.assign({ className: styles.info }, { children: [_jsx("span", Object.assign({ className: styles.header }, { children: _t('main.lost-server-connection') })), !apiError && !isReconnecting && (_jsx("span", Object.assign({ className: styles.label }, { children: _t('main.reconnecting-in', { seconds: currentDelay }) })))] })), _jsx(Button, Object.assign({ loading: isReconnecting && !apiError, onClick: tryReconnecting, variant: "secondary" }, { children: _t('main.retry-now') }))] })) })));
};
export default ConnectionStatus;
