import React from 'react';
import { PageLayout } from '../global/PageLayout';
import _t from 'counterpart';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { actions, crmService } from 'wintrado-api';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Desktop } from 'utils/responsiveHelpers';
import { BackButton } from '../BackButton';
import Button from '../Button/Button';
import AnyLink from 'components/AnyLink/AnyLink';
import Loader from 'components/Loader/Loader';
import ErrorScreen from 'components/ErrorScreen/ErrorScreen';

class PageMessageInner extends React.PureComponent {
	state = {
		loading: !this.props.currentMessage,
		currentMessage: this.props.currentMessage,
	};

	componentDidMount() {
		const { currentMessage } = this.state;

		if (!currentMessage) {
			this.getCurrentMessage();
		} else if (currentMessage.readAt === null) {
			this.markMessageAsRead(currentMessage.id);
		}
	}

	markMessageAsRead = async (id) => {
		try {
			await crmService.markMessageAsRead(id);
			// TODO remove after implementing unread count refreshing
			this.props.fetchMessagesUnreadCount();
		} catch (e) {
			console.error(e);
		}
	};

	getCurrentMessage = async () => {
		const { id: messageId } = this.props.match.params;

		try {
			this.setState({
				loading: true,
			});
			const fetchedMessage = await crmService.getMessage(messageId);
			this.setState({
				currentMessage: fetchedMessage.message[0],
				loading: false,
			});

			if (fetchedMessage.readAt === null) {
				this.markMessageAsRead(messageId);
			}
		} catch (e) {
			console.error(`Failed to fetch message with ID: ${messageId}`, e);
			this.setState({
				loading: false,
			});
		}
	};

	goBack = () => {
		this.props.history.goBack();
	};

	renderMessageInnerContent = () => {
		const { loading, currentMessage } = this.state;
		if (loading) {
			return <Loader />;
		}
		if (currentMessage) {
			return (
				<div className="message-wrapper">
					<div className="message-wrapper__content">
						<span className="message-wrapper__date">{new Date(currentMessage.createdAt).toLocaleDateString()}</span>
						<p className="message-wrapper__title">{currentMessage.subject}</p>
						<p className="message-wrapper__message">{currentMessage.text}</p>
					</div>
					{currentMessage.actions.length > 0 && (
						<div className="message-wrapper__actions">
							{currentMessage.actions.map((item) => (
								<AnyLink key={item.type} className={`message-action ${item.type}`} to={item.url}>
									<Button green={item.type === 'yes'} red={item.type === 'no'} gray={item.type === 'learn-more'}>
										{_t.translate(`main.${item.type}`)}
									</Button>
								</AnyLink>
							))}
						</div>
					)}
				</div>
			);
		}
		return <ErrorScreen />;
	};

	render() {
		const { currentMessage } = this.state;
		return (
			<PageLayout
				className={classNames('message-inner', {
					'message-inner--with-actions': currentMessage?.actions.length > 0,
				})}
				title={_t('page.messages')}
			>
				<Desktop>
					<BackButton />
				</Desktop>
				{this.renderMessageInnerContent()}
			</PageLayout>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const { id: messageId } = ownProps.match.params;
	const messages = state.broker.messages?.data ?? [];
	const currentMessage = messages.find((item) => item.id === messageId);
	return {
		currentMessage,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			fetchMessagesUnreadCount: actions.fetchMessagesUnreadCount,
		},
		dispatch
	);
};
export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(PageMessageInner);
