import { z } from "zod";
import { asOptionalField } from "../utils/zodUtils";
import { PositionSide } from "../reducers/positions/types";
const commonPlaceOrderSchema = z.object({
    symbol: z.string().min(1),
    side: z.nativeEnum(PositionSide),
    size: z.coerce.number().min(0.01),
    stopLoss: asOptionalField(z.coerce.number().positive()).nullable(),
    takeProfit: asOptionalField(z.coerce.number().positive()).nullable(),
    closePositionAfterSeconds: asOptionalField(z.coerce.number().positive()).nullable(),
    invest: asOptionalField(z.coerce.number().positive()).nullable()
});
export const placeOrderSchema = z.discriminatedUnion("type", [
    z.object({
        type: z.literal("market")
    }).merge(commonPlaceOrderSchema),
    z.object({
        type: z.literal("limit"),
        limitPrice: z.coerce.number().positive()
    }).merge(commonPlaceOrderSchema),
    z.object({
        type: z.literal("stop"),
        stopPrice: z.coerce.number().positive()
    }).merge(commonPlaceOrderSchema)
]);
export const editOrderSchema = z.object({
    orderId: z.number(),
    size: z.coerce.number().min(0.01),
    stopPrice: asOptionalField(z.coerce.number().positive()).nullable(),
    stopLoss: asOptionalField(z.coerce.number().positive()).nullable(),
    takeProfit: asOptionalField(z.coerce.number().positive()).nullable(),
    limitPrice: asOptionalField(z.coerce.number().positive()),
});
export const editPositionSchema = z.object({
    positionId: z.number(),
    stopLoss: asOptionalField(z.coerce.number().positive()).nullable(),
    takeProfit: asOptionalField(z.coerce.number().positive()).nullable()
});
