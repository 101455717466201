import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import _t from 'counterpart';
import Spacer from 'components/Spacer/Spacer';
import Badge from 'components/Badge/Badge';
import MobilePositionsList from 'components/MobilePositionsList/MobilePositionsList';
import MobileOrdersList from 'components/MobileOrdersList/MobileOrdersList';
import Tabs from 'components/Tabs/Tabs';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../storeHooks';
import { selectors } from 'wintrado-api';
import { useGoBackTo } from '../../hooks/useGoBackTo';
import MobileScreenLayout from '../../layout/MobileScreenLayout/MobileScreenLayout';
const MobilePositionsOrdersScreen = () => {
    const history = useHistory();
    const activeTabBasedOnRoute = history.location.pathname.includes('/trading/positions')
        ? 'open-positions'
        : 'pending-orders';
    const [activeTab, setActiveTab] = useState(activeTabBasedOnRoute);
    useEffect(() => {
        if (activeTab === 'open-positions') {
            history.replace('/trading/positions');
        }
        else {
            history.replace('/trading/orders');
        }
    }, [activeTab, history]);
    const openPositions = useAppSelector(selectors.openPositionsSelector);
    const pendingOrders = useAppSelector(selectors.pendingOrdersSelector);
    const goBack = useGoBackTo('/trading');
    return (_jsx(MobileScreenLayout, Object.assign({ onBackClick: goBack, title: _t('main.positions_and_orders') }, { children: _jsx(Tabs, { value: activeTab, onValueChange: setActiveTab, content: [
                {
                    value: 'open-positions',
                    label: (_jsxs(_Fragment, { children: [_t('main.open_positions'), openPositions.length > 0 && (_jsxs(_Fragment, { children: [_jsx(Spacer, { x: "extraSmall" }), _jsx(Badge, { value: openPositions.length })] }))] })),
                    content: _jsx(MobilePositionsList, { positions: openPositions }),
                },
                {
                    value: 'pending-orders',
                    label: (_jsxs(_Fragment, { children: [_t('main.pending_orders'), pendingOrders.length > 0 && (_jsxs(_Fragment, { children: [_jsx(Spacer, { x: "extraSmall" }), _jsx(Badge, { value: pendingOrders.length })] }))] })),
                    content: _jsx(MobileOrdersList, { orders: pendingOrders }),
                },
            ] }) })));
};
export default React.memo(MobilePositionsOrdersScreen);
