import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styles from './PendingOrdersList.module.scss';
import _t from 'counterpart';
import { selectors } from 'wintrado-api';
import { useAppSelector } from '../../storeHooks';
import { OrderSide } from 'wintrado-api/src/reducers/orders/types';
import PendingOrdersItem from '../PendingOrderItem/PendingOrderItem';
const PendingOrdersList = ({ pendingOrders, newOrderIds }) => {
    const instruments = useAppSelector((state) => state.instruments);
    const latestQuotes = useAppSelector(selectors.latestQuotesSelector);
    return (_jsxs("div", Object.assign({ className: styles.table }, { children: [_jsxs("div", Object.assign({ className: styles.header }, { children: [_jsx("span", { children: "#" }), _jsx("span", { children: _t('main.symbol') }), _jsx("span", { children: _t('main.placed_at') }), _jsx("span", { children: _t('main.type') }), _jsx("span", { children: _t('main.volume') }), _jsx("span", { children: _t('main.stop_price') }), _jsx("span", { children: _t('main.limit_price') }), _jsx("span", { children: _t('main.current_price') }), _jsx("span", { children: _t('main.order_duration') })] })), pendingOrders
                .sort((a, b) => b.id - a.id)
                .map((order) => {
                if (!order.symbol) {
                    return null;
                }
                const instrument = instruments[order.symbol];
                const latestQuote = latestQuotes[order.symbol];
                const currentPrice = order.side === OrderSide.BUY ? latestQuote === null || latestQuote === void 0 ? void 0 : latestQuote.bid : latestQuote === null || latestQuote === void 0 ? void 0 : latestQuote.ask;
                const isNew = newOrderIds === null || newOrderIds === void 0 ? void 0 : newOrderIds.includes(order.positionId);
                if (!instrument) {
                    throw new Error(`Instrument ${order.symbol} not found`);
                }
                return (_jsx(PendingOrdersItem, { order: order, isNew: Boolean(isNew), instrument: instrument, currentPrice: currentPrice }, order.positionId));
            })] })));
};
export default React.memo(PendingOrdersList);
