import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { actions } from 'wintrado-api';
import Select from 'components/Select/Select';
import SelectItem from 'components/Select/SelectItem';
import Spacer from 'components/Spacer/Spacer';
import { useAppDispatch, useAppSelector } from '../../storeHooks';
const LANGUAGES = [
    { name: 'English', code: 'en' },
    { name: 'Español', code: 'es' },
    { name: 'Francais', code: 'fr' },
    { name: 'Português ', code: 'pt' },
    { name: 'عربى', code: 'ar' },
    { name: 'Türkçe', code: 'tr' },
    { name: 'Pусский', code: 'ru' },
    { name: 'Italiano', code: 'it' },
    { name: 'Deutsch', code: 'de' },
    { name: 'हिन्दी', code: 'hi' },
    { name: 'اردو', code: 'ur' },
    { name: 'Kiswahili', code: 'sw' },
    { name: 'Malay', code: 'my' },
    { name: '日本語', code: 'ja' },
    { name: 'Tiếng Việt ', code: 'vi' },
    { name: 'ភាសាខ្មែរ', code: 'km' },
    { name: '中文', code: 'zh' },
];
const LanguageSelect = () => {
    const userLanguage = useAppSelector((state) => state.settings.language);
    const dispatch = useAppDispatch();
    const handleLanguageChanged = (language) => {
        dispatch(actions.userSaveLanguage(language));
    };
    const currentLanguage = LANGUAGES.find((obj) => obj.code === userLanguage);
    if (!currentLanguage) {
        throw Error(`Language with code ${userLanguage} cannot be found.`);
    }
    return (_jsx(Select, Object.assign({ value: currentLanguage.code, onValueChange: handleLanguageChanged }, { children: LANGUAGES.map((item) => (_jsxs(SelectItem, Object.assign({ value: item.code }, { children: [_jsx("img", { alt: `${item.code}-icon`, src: require(`/src/old/icons/countries/${item.code}.svg?url`) }), _jsx(Spacer, { x: 'small' }), _jsx("span", { children: item.name })] }), item.code))) })));
};
export default LanguageSelect;
