import { all, put, select, takeLatest } from 'redux-saga/effects';
import { API_DISCONNECTED, userClearCredentials } from '../actions';
function* handleApiDisconnected(action) {
    const { remember } = yield select(state => state.settings);
    if (!remember) {
        yield put(userClearCredentials());
    }
}
function* connectionSaga() {
    yield all([
        takeLatest(API_DISCONNECTED, handleApiDisconnected),
    ]);
}
export default connectionSaga;
