import * as z from 'zod';
export var PositionStatusCode;
(function (PositionStatusCode) {
    PositionStatusCode["OPEN"] = "open";
    PositionStatusCode["CLOSED"] = "closed";
    PositionStatusCode["NEW"] = "new";
    PositionStatusCode["ERROR_ON_OPENING"] = "error-on-opening";
    PositionStatusCode["ERROR_ON_CLOSING"] = "error-on-closing";
})(PositionStatusCode || (PositionStatusCode = {}));
export var PositionSide;
(function (PositionSide) {
    PositionSide["BUY"] = "buy";
    PositionSide["SELL"] = "sell";
})(PositionSide || (PositionSide = {}));
export var PositionCloseReason;
(function (PositionCloseReason) {
    PositionCloseReason["USER_TRADING"] = "user_trading";
    PositionCloseReason["TIME_EXPIRED"] = "time_expired";
    PositionCloseReason["INVEST_EXCEEDED"] = "invest_exceeded";
    PositionCloseReason["COPYTRADING"] = "copytrading";
    PositionCloseReason["STOP_LOSS"] = "stop_loss";
    PositionCloseReason["STOP_OUT"] = "stop_out";
    PositionCloseReason["USER_REQUESTED"] = "user_requested";
})(PositionCloseReason || (PositionCloseReason = {}));
export var PositionPlatform;
(function (PositionPlatform) {
    PositionPlatform["MT4"] = "mt4";
    PositionPlatform["WINTRADO"] = "wintrado";
})(PositionPlatform || (PositionPlatform = {}));
export const openedPositionSchema = z.object({
    status: z.literal(PositionStatusCode.OPEN),
    symbol: z.string().nonempty(),
    side: z.nativeEnum(PositionSide),
    openPrice: z.coerce.number(),
    openedAt: z.coerce.date(),
    margin: z.coerce.number(),
    platform: z.nativeEnum(PositionPlatform),
    positionId: z.number(),
    profitLoss: z.coerce.number(),
    stopLoss: z.coerce.number().nullable(),
    takeProfit: z.coerce.number().nullable(),
    size: z.coerce.number(),
    swap: z.coerce.number(),
    invest: z.coerce.number().optional(),
    closePositionAfterSeconds: z.coerce.number().optional().nullable(),
});
