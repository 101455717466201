var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import reduceReducers from 'reduce-reducers';
import { createMigrate, createTransform, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// @ts-ignore
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import positions from './positions/positionsReducer';
import balance from './balance/balanceReducer';
import instruments from './instruments/instrumentsReducer';
import quotes from './quotes/quotesReducer';
import candles from './candles/candlesReducer';
import coreApiReducer from './coreApi/coreApiReducer';
import error from './error/errorReducer';
import connection from './connection/connectionReducer';
import settings from './settings/settingsReducer';
import leaderboardReducer from './leaderboard/leaderboardReducer';
import tradingScreenReducer from './tradingScreen/tradingScreenReducer';
import brokerReducer from './broker/brokerReducer';
import accountHistoryReducer from './accountHistory/accountHistoryReducer';
import { initialState } from '../store';
import eventsReducer from './events/eventsReducer';
import trendQuotesReducer from './trendQuotes/trendQuotesReducer';
import { removeEmptyValuesFromObject } from '../utils/helpers';
import timeCalculationsReducer from './timeCalculations/timeCalculationsReducer';
import modalsReducer from './modals/modalsReducer';
import instrumentsTrendReducer from './instrumentsTrend/instrumentsTrendReducer';
import configReducer from './config/configReducer';
import ordersReducer from './orders/ordersReducer';
import priceAlertReducer from './priceAlert/priceAlertReducer';
import proTradingScreenReducer from './proTradingScreen/proTradingScreenReducer';
const migrations = {
    0: (state) => {
        return Object.assign(Object.assign({}, state), { settings: Object.assign(Object.assign({}, state.settings), { language: !!state.settings.language
                    ? state.settings.language
                    : initialState.settings.language }) });
    },
    1: (state) => {
        return Object.assign(Object.assign({}, state), { settings: Object.assign(Object.assign({}, state.settings), { userSavedLanguage: state.settings.language !== initialState.settings.language }) });
    },
};
const tradingScreenTransformer = createTransform((inboundState) => {
    const { closePositionAfterSeconds, investSelected, profitLossPerInstrument } = inboundState;
    return { closePositionAfterSeconds, investSelected, profitLossPerInstrument };
}, (outboundState) => {
    return removeEmptyValuesFromObject(outboundState);
}, { whitelist: ['tradingScreen'] });
const userTransformer = createTransform((inboundState) => {
    const { token, refreshToken } = inboundState;
    return { token, refreshToken };
}, (outboundState) => {
    return outboundState;
}, { whitelist: ['user'] });
const eventsTransformer = createTransform((inboundState) => {
    const { loggingIn, stopOutOccurred, loginTimedOut } = inboundState, rest = __rest(inboundState, ["loggingIn", "stopOutOccurred", "loginTimedOut"]);
    return rest;
}, (outboundState) => {
    const { loggingIn, stopOutOccurred, loginTimedOut } = outboundState, rest = __rest(outboundState, ["loggingIn", "stopOutOccurred", "loginTimedOut"]);
    return rest;
}, { whitelist: ['events'] });
const settingsTransformer = createTransform((inboundState) => {
    const { instrumentToPreselect } = inboundState, rest = __rest(inboundState, ["instrumentToPreselect"]);
    return rest;
}, (outboundState) => {
    const { instrumentToPreselect } = outboundState, rest = __rest(outboundState, ["instrumentToPreselect"]);
    return rest;
}, { whitelist: ['settings'] });
const settingsPersistConfig = {
    key: 'settings',
    version: 3,
    storage,
    whitelist: ['settings', 'devMenu', 'events', 'tradingScreen', 'user', 'config', 'activeInstrumentSymbol'],
    stateReconciler: autoMergeLevel2,
    migrate: createMigrate(migrations, { debug: true }),
    transforms: [tradingScreenTransformer, userTransformer, eventsTransformer, settingsTransformer],
};
// @ts-ignore
const rootReducer = persistReducer(settingsPersistConfig, 
// @ts-ignore
reduceReducers(settings, error, connection, positions, balance, instruments, quotes, candles, coreApiReducer, tradingScreenReducer, proTradingScreenReducer, brokerReducer, accountHistoryReducer, eventsReducer, leaderboardReducer, trendQuotesReducer, timeCalculationsReducer, modalsReducer, instrumentsTrendReducer, configReducer, ordersReducer, priceAlertReducer));
export default rootReducer;
