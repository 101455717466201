import { takeEvery, put, takeLatest } from 'redux-saga/effects';
import { INSTRUMENTS_RECEIVED, INSTRUMENTS_SET_ACTIVE, setActiveInstrument, subscribeToQuotesBatchForSymbols, subscribeToQuotesForSymbols, unsubscribeFromBatchQuotes, unsubscribeFromQuotes, updateInstruments, } from '../actions';
import * as instrumentsHelpers from '../utils/instrumentsHelpers';
import { activePositionSelector } from '../selectors';
import { select } from 'redux-saga/effects';
const BATCH_QUOTES_INTERVAL = 1000;
const BATCH_QUOTES_FORMAT = 'protobuf';
function* handleReceiveInstruments(action) {
    const { instruments, timezone, defaultInstrument } = action.payload;
    const activeInstrumentSymbol = yield select((state) => state.activeInstrumentSymbol);
    const activePosition = yield select(activePositionSelector);
    const instrumentToPreselect = yield select((state) => state.settings.instrumentToPreselect);
    const cleanInstruments = instruments.reduce((acc, dataEntry) => {
        if (dataEntry.symbol === undefined) {
            return acc;
        }
        acc[dataEntry.symbol] = instrumentsHelpers.sanitizeBaseInstrument(dataEntry);
        return acc;
    }, {});
    yield put(updateInstruments(cleanInstruments, timezone));
    let instrumentToSetActive = null;
    if (activePosition === null || activePosition === void 0 ? void 0 : activePosition.symbol) {
        instrumentToSetActive = activePosition.symbol;
    }
    else if (instrumentToPreselect && cleanInstruments[instrumentToPreselect]) {
        instrumentToSetActive = instrumentToPreselect;
    }
    else if (activeInstrumentSymbol && cleanInstruments[activeInstrumentSymbol]) {
        instrumentToSetActive = activeInstrumentSymbol;
    }
    else if (defaultInstrument) {
        instrumentToSetActive = defaultInstrument;
    }
    if (instruments.length === 0) {
        return;
    }
    if (instrumentToSetActive) {
        yield put(setActiveInstrument(instrumentToSetActive));
    }
    else {
        throw Error('Received instruments, none set to active as none of the following are set - activePosition, defaultInstrument, instrumentToPreselect');
    }
}
function* changeActiveInstrumentAndUnsubscribeHandler(action) {
    const activeInstrumentSymbol = action.payload;
    if (!activeInstrumentSymbol) {
        throw new Error('Trying to set null as active instrument');
    }
    const activeInstrumentSubscriptionId = yield select((state) => state.activeInstrumentSubscriptionId);
    const instrumentsBatchSubscriptionId = yield select((state) => state.instrumentsBatchSubscriptionId);
    const instruments = yield select((state) => state.instruments);
    const instrumentsForBatch = Object.keys(instruments).filter((i) => i !== activeInstrumentSymbol);
    if (activeInstrumentSubscriptionId) {
        yield put(unsubscribeFromQuotes(activeInstrumentSubscriptionId));
    }
    yield put(subscribeToQuotesForSymbols([activeInstrumentSymbol]));
    if (instrumentsBatchSubscriptionId) {
        yield put(unsubscribeFromBatchQuotes(instrumentsBatchSubscriptionId));
    }
    yield put(subscribeToQuotesBatchForSymbols(instrumentsForBatch, BATCH_QUOTES_FORMAT, BATCH_QUOTES_INTERVAL));
}
function* instrumentsSaga() {
    yield takeEvery(INSTRUMENTS_RECEIVED, handleReceiveInstruments);
    yield takeLatest(INSTRUMENTS_SET_ACTIVE, changeActiveInstrumentAndUnsubscribeHandler);
}
export default instrumentsSaga;
