var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import _t from 'counterpart';
import * as Sentry from '@sentry/react';
import { actions, PositionSide, store } from 'wintrado-api';
import { formatCurrency } from 'utils/currency';
import config from 'config';
import { StakeMode } from 'wintrado-api/src/reducers/instruments/types';
import axios from 'axios';
export let referralParam;
export function setReferralParam(referral) {
    referralParam = referral;
}
export function getDuration(duration) {
    return _t.translate(`config.trade_duration.${duration}`, {
        fallback: `${duration / 60} ${_t.translate('main.minutes').toLowerCase()}`,
    });
}
function getSizeLabelFromValue(size, instrument) {
    if (instrument.stakeMode === StakeMode.SPREAD_BETTING) {
        return `${formatCurrency(Number(size), instrument.currency)} / ${_t.translate('main.pkt')}`;
    }
    if (instrument.stakeMode === StakeMode.COINS) {
        return _t.translate('main.stake_mode.coins', { count: Number(size) });
    }
    if (instrument.stakeMode === StakeMode.SHARES) {
        return _t.translate('main.stake_mode.shares', { count: Number(size) });
    }
    throw Error(`Unsupported StakeMode: ${instrument.stakeMode}`);
}
export function getSizeLabel(sizeKey, instrument) {
    const size = instrument === null || instrument === void 0 ? void 0 : instrument.sizes[sizeKey.toString(10)];
    if (!size) {
        return null;
    }
    return getSizeLabelFromValue(size, instrument);
}
export function isMobileLayout() {
    const query = window.matchMedia('(max-width: 1120px)');
    return query.matches;
}
export function isOperaMini() {
    return ((navigator.userAgent.indexOf('OPR/') > -1 && navigator.userAgent.indexOf('Mobile') > -1) ||
        navigator.userAgent.indexOf('Presto/') > -1);
}
function pad(num) {
    return `0${num}`.slice(-2);
}
export function formatSeconds(seconds) {
    let secs = seconds;
    let minutes = Math.floor(secs / 60);
    secs = Math.floor(secs % 60);
    const hours = Math.floor(minutes / 60);
    minutes %= 60;
    return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
}
export function getSoundbanksAndUpdateProgress() {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
            store.dispatch(actions.setSoundbankLoading(true));
            // TODO remove any and refactor this mess
            // const masterBankResponse = await fetch('/soundbanks/Master Bank.bank');
            const masterBankResponse = yield fetch('/soundbanks/Master Bank_no_reverb.bank');
            // const masterBankStringsResponse = await fetch('/soundbanks/Master Bank.strings.bank');
            const masterBankStringsResponse = yield fetch('/soundbanks/Master Bank_no_reverb.strings.bank');
            const masterBankContentLength = Number(masterBankResponse.headers.get('Content-Length'));
            const masterBankStringsContentLength = Number(masterBankStringsResponse.headers.get('Content-Length'));
            const masterBankReader = masterBankResponse.body.getReader();
            const masterBankStringsReader = masterBankStringsResponse.body.getReader();
            let receivedMasterBankLength = 0;
            let receivedMasterBankStringsLength = 0;
            while (true) {
                // eslint-disable-next-line no-await-in-loop
                const { done: masterBankDone, value: masterBankValue } = yield masterBankReader.read();
                // eslint-disable-next-line no-await-in-loop
                const { done: masterBankStringsDone, value: masterBankStringsValue } = yield masterBankStringsReader.read();
                if (masterBankDone && masterBankStringsDone) {
                    store.dispatch(actions.setSoundbankLoading(false));
                    resolve(true);
                    break;
                }
                if (!masterBankDone) {
                    receivedMasterBankLength += masterBankValue.length;
                }
                if (!masterBankStringsDone) {
                    receivedMasterBankStringsLength += masterBankStringsValue.length;
                }
                const totalReceivedLength = receivedMasterBankLength + receivedMasterBankStringsLength;
                const totalContentLength = masterBankContentLength + masterBankStringsContentLength;
                const percentage = (totalReceivedLength / totalContentLength) * 100;
                store.dispatch(actions.setSoundbankLoadingPercentage(percentage));
            }
        }));
    });
}
export function isStandalone() {
    // @ts-ignore
    return (window.matchMedia('(display-mode: fullscreen), (display-mode: standalone)').matches ||
        window.navigator.standalone ||
        document.referrer.includes('android-app://'));
}
export function isIos() {
    const { userAgent } = window.navigator;
    return /iphone|ipad|ipod/i.test(userAgent);
}
export function isAndroid() {
    const { userAgent } = window.navigator;
    return /(android)/i.test(userAgent);
}
export function isNotChrome() {
    return (!isIos() &&
        !isAndroid() &&
        !(Boolean(window.chrome) && /google/i.test(navigator.vendor) && !/OPR/i.test(navigator.userAgent)));
}
export function releaseVersion() {
    const packageJson = require('../package.json');
    return packageJson.version;
}
// __webpack_hash__ is function in dev and string in prod, don't remove the const, it'll break
export const webpackHash = () => {
    // @ts-ignore
    const whType = typeof __webpack_hash__;
    // @ts-ignore
    return whType === 'function' ? __webpack_hash__() : __webpack_hash__;
};
export const injectZendeskScript = () => {
    const zendeskChatScript = document.createElement('script');
    zendeskChatScript.id = 'ze-snippet';
    zendeskChatScript.src = `https://static.zdassets.com/ekr/snippet.js?key=${config.zendeskChatKey}`;
    document.getElementsByTagName('head')[0].append(zendeskChatScript);
};
const waitForZendesk = () => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve, reject) => {
        const TIMEOUT = 3000;
        let elapsed = 0;
        const interval = setInterval(() => {
            if (window.zE !== undefined) {
                resolve();
            }
            elapsed += 100;
            if (elapsed >= TIMEOUT) {
                clearInterval(interval);
                reject(Error('Zopim loading timeout'));
            }
        }, 100);
    });
});
/**
 * This does not currently hide the launcher bubble as Zendesk has removed the API
 * to do so in their latest version: https://developer.zendesk.com/documentation/zendesk-web-widget-sdks/sdks/web/sdk_api_reference/
 *
 * This shouldn't be an issue if the customer chooses a "custom launcher" in their config which does
 * not inject an overlay button. The chat can then be opened from the Support menu item.
 */
export const hideZendeskBubble = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        if (window.zE !== undefined) {
            window.zE('messenger', 'close');
        }
        else {
            yield waitForZendesk();
            hideZendeskBubble();
        }
    }
    catch (e) {
        Sentry.captureException(e);
    }
});
export const showZendeskBubble = () => {
    if (window.zE !== undefined) {
        window.zE('messenger', 'open');
    }
};
const showZendeskWindow = () => {
    window.zE('messenger', 'open');
};
export const loadLiveChat = () => __awaiter(void 0, void 0, void 0, function* () {
    if (window.zE) {
        showZendeskWindow();
    }
    else {
        store.dispatch(actions.showLoading());
        injectZendeskScript();
        try {
            yield waitForZendesk();
            showZendeskWindow();
        }
        catch (e) {
            Sentry.captureException(e);
        }
        finally {
            store.dispatch(actions.hideLoading());
        }
    }
});
export const capitalize = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};
export const millisecondsToHoursDaysMinutesSeconds = (millis) => {
    let delta = Math.abs(millis) / 1000;
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;
    const seconds = Math.floor(delta % 60);
    return {
        hours,
        days,
        minutes,
        seconds,
    };
};
export const ordinalSuffixFor = (i) => {
    const j = i % 10;
    const k = i % 100;
    if (j === 1 && k !== 11) {
        return 'st';
    }
    if (j === 2 && k !== 12) {
        return 'nd';
    }
    if (j === 3 && k !== 13) {
        return 'rd';
    }
    return 'th';
};
export const getIconForCloseReasonIfExists = (closeReason) => {
    try {
        return require(`./old/icons/stop-reasons/${closeReason}.svg?url`);
    }
    catch (e) {
        Sentry.captureException(new Error(`Missing icon for close reason ${closeReason}`));
        return null;
    }
};
const generateRandomString = () => {
    return Array(10)
        .fill('123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz')
        .map((x) => {
        return x[Math.floor(Math.random() * x.length)];
    })
        .join('');
};
export const requestOAuthLogin = (language, walletId) => {
    const { oAuth } = config;
    const { authorizeURL, clientId } = oAuth;
    const state = generateRandomString();
    window.sessionStorage.setItem('state', state);
    window.location = authorizeURL(language, window.location.origin, clientId, state, walletId);
};
export const isSet = (input) => {
    return input !== null && input !== undefined;
};
export const decimalToPercentageString = (value) => {
    const percentage = (value * 100).toFixed(2);
    const prettyPercentage = parseFloat(percentage).toLocaleString('en-US');
    return `${prettyPercentage}%`;
};
export const formatPercentage = (value) => {
    const numValue = typeof value === 'string' ? parseFloat(value) : value;
    const decimalPercentageLabel = `${numValue.toFixed(2)}%`;
    if (numValue > 0) {
        return `+ ${decimalPercentageLabel}`;
    }
    if (numValue < 0) {
        return `- ${decimalPercentageLabel}`;
    }
    return decimalPercentageLabel;
};
export const numberToCompactNotation = (num) => {
    const formatter = Intl.NumberFormat('en', { notation: 'compact' });
    return formatter.format(num);
};
export const loadProfitLottie = () => __awaiter(void 0, void 0, void 0, function* () {
    const data = yield import(/* webpackChunkName: "profit", webpackPreload: true */ './lotties/profit.json');
    return data.default;
});
export const loadLossLottie = () => __awaiter(void 0, void 0, void 0, function* () {
    const data = yield import(/* webpackChunkName: "loss", webpackPreload: true */ './lotties/loss.json');
    return data.default;
});
export const timestampToLocalTimestampSeconds = (timestamp) => {
    const d = new Date(timestamp);
    return (Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds()) / 1000);
};
export const decimalPlacesToPrecisionString = (decimalPlaces) => {
    if (decimalPlaces === 0) {
        return 1;
    }
    else {
        return Number(`0.${'0'.repeat(decimalPlaces - 1)}1`);
    }
};
export const getInitials = (input) => {
    var _a;
    const names = input.split(' ');
    if (!names[0]) {
        return '';
    }
    let initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
        initials += (_a = names[names.length - 1]) === null || _a === void 0 ? void 0 : _a.substring(0, 1).toUpperCase();
    }
    return initials;
};
export const orderSideToPositionSide = (orderSide) => {
    if (orderSide === 'ask') {
        return PositionSide.BUY;
    }
};
export const formatDateTime = (date) => {
    return new Intl.DateTimeFormat(_t.getLocale(), {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    }).format(new Date(date));
};
export const formatSpread = (spread, instrumentDecimals) => {
    return Math.round(spread * Math.pow(10, instrumentDecimals));
};
export function extractErrorMessage(error) {
    var _a, _b, _c, _d, _e;
    if (!error) {
        return null;
    }
    if ((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) {
        if (error.response.data.error.message) {
            if (typeof error.response.data.error.message !== 'string') {
                return _t('error.unexpected_error_occurred');
            }
            return error.response.data.error.message;
        }
    }
    // TODO get rid of this after fixing errors on BE
    if (((_e = (_d = (_c = error.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.errors) === null || _e === void 0 ? void 0 : _e.length) && error.response.data.errors[0].field === null) {
        return error.response.data.errors[0].message;
    }
    return error.message;
}
export const extractErrorForField = (error, fieldName) => {
    var _a, _b, _c, _d;
    if (!error || !(error instanceof Error) || !axios.isAxiosError(error)) {
        return null;
    }
    if (((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.errors) && Array.isArray(error.response.data.errors)) {
        return (_d = (_c = error.response.data.errors.find((e) => e.field === fieldName)) === null || _c === void 0 ? void 0 : _c.message) !== null && _d !== void 0 ? _d : null;
    }
    return null;
};
export const getHumanReadableDateRange = (start, end) => {
    const weekdays = [
        _t('main.sunday'),
        _t('main.monday'),
        _t('main.tuesday'),
        _t('main.wednesday'),
        _t('main.thursday'),
        _t('main.friday'),
        _t('main.saturday'),
    ];
    const startDay = weekdays[start.getDay()];
    const endDay = weekdays[end.getDay()];
    const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    };
    const rangeString = `${startDay} ${formatTime(start)} - ${endDay} ${formatTime(end)}`;
    return rangeString;
};
export const priceChange = (op, cp) => {
    const openPrice = Number(op);
    const closePrice = Number(cp);
    if (closePrice > openPrice) {
        return ((closePrice - openPrice) / openPrice) * 100;
    }
    if (closePrice < openPrice) {
        return ((openPrice - closePrice) / openPrice) * 100;
    }
    return 0;
};
export const transactionsSum = (a, val, type) => {
    const amount = Number(val);
    const value = isNaN(Number(amount)) ? 0 : Number(amount);
    if (type === 'WITHDRAWAL') {
        return a - value;
    }
    return a + value;
};
export const getNowAndMinusMonth = () => {
    const start = new Date();
    start.setDate(start.getDate() - 30);
    const end = new Date();
    return { start, end };
};
export function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
