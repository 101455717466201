import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styles from './PositionsList.module.scss';
import _t from 'counterpart';
import { PositionSide, selectors } from 'wintrado-api';
import { useAppSelector } from '../../storeHooks';
import PositionListItem from '../PositionsListItem/PositionsListItem';
const PositionsList = ({ newPositionIds, positions }) => {
    const instruments = useAppSelector((state) => state.instruments);
    const latestQuotes = useAppSelector(selectors.latestQuotesSelector);
    return (_jsxs("div", Object.assign({ className: styles.table }, { children: [_jsxs("div", Object.assign({ className: styles.header }, { children: [_jsx("span", { children: "#" }), _jsx("span", { children: _t('main.symbol') }), _jsx("span", { children: _t('main.time') }), _jsx("span", { children: _t('main.type') }), _jsx("span", { children: _t('main.volume') }), _jsx("span", { children: _t('main.open_price') }), _jsx("span", { children: _t('main.stop_loss') }), _jsx("span", { children: _t('main.take_profit') }), _jsx("span", { children: _t('main.current_price') }), _jsx("span", { children: _t('main.profit') })] })), positions
                .sort((a, b) => b.positionId - a.positionId)
                .map((position) => {
                if (!position.symbol) {
                    return null;
                }
                const instrument = instruments[position.symbol];
                const latestQuote = latestQuotes[position.symbol];
                const currentPrice = position.side === PositionSide.BUY ? latestQuote === null || latestQuote === void 0 ? void 0 : latestQuote.bid : latestQuote === null || latestQuote === void 0 ? void 0 : latestQuote.ask;
                const isNew = newPositionIds === null || newPositionIds === void 0 ? void 0 : newPositionIds.includes(position.positionId);
                if (!instrument) {
                    throw Error(`Instrument ${position.symbol} not found`);
                }
                return (_jsx(PositionListItem, { position: position, isNew: Boolean(isNew), instrument: instrument, currentPrice: currentPrice }, position.positionId));
            })] })));
};
export default React.memo(PositionsList);
