import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import config from 'config';
import { loadLiveChat, requestOAuthLogin } from '../../../helpers';
import _t from 'counterpart';
import { useAppSelector } from '../../../storeHooks';
import Button from '../Button/Button';
import { OpenAccountButton } from '../main/OpenAccountButton';
const LoginButton = () => {
    const userLanguage = useAppSelector((state) => state.settings.language);
    const handleLogin = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (config.cashier.loginLink !== undefined) {
            window.location = config.cashier.loginLink(userLanguage);
        }
        else {
            requestOAuthLogin(userLanguage);
        }
    };
    return (_jsx(Button, Object.assign({ green: true, fullWidth: true, onClick: handleLogin }, { children: _t.translate('login.submit') })));
};
const LoginForm = () => {
    const handleHelp = (event) => {
        event.preventDefault();
        loadLiveChat();
    };
    return (_jsxs("div", Object.assign({ className: "close-login login__form form" }, { children: [_jsxs("div", Object.assign({ className: "login__submit" }, { children: [_jsx(LoginButton, {}), Boolean(config.cashier.registrationLink) && (_jsxs(_Fragment, { children: [_jsx("span", Object.assign({ className: "login__button_separator_label" }, { children: _t.translate('main.or') })), _jsx(OpenAccountButton, { fullWidth: true })] }))] })), _jsx("div", Object.assign({ className: "login__links" }, { children: Boolean(config.zendeskChatKey) && (_jsx("div", Object.assign({ className: "login__help" }, { children: _jsx("a", Object.assign({ href: "apps/webtrader-pro/src/old/components/login#", className: "help", onClick: handleHelp }, { children: _t.translate('main.help') })) }))) }))] })));
};
export default LoginForm;
