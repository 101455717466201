import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import styles from './BrandLogo.module.scss';
import { useAppSelector } from '../../storeHooks';
import WebtraderLogo from '../../old/images/brands/wintrado/logo.svg?url';
import WebtraderLogoRectangular from '../../old/images/brands/wintrado/logo-mobile.png';
import EmpirexInvestLogo from '../../old/images/brands/empirexinvest/logo.png';
import EmpirexInvestLogoRectangular from '../../old/images/brands/empirexinvest/logo-mobile.png';
import GFMarketsLogo from '../../old/images/brands/gfmarkets/logo.svg?url';
import GFMarketsLogoRectangular from '../../old/images/brands/gfmarkets/logo-mobile.png';
import XBloomCapitalLogo from '../../old/images/brands/xbloomcapital/logo.svg?url';
import XBloomCapitalLogoRectangular from '../../old/images/brands/xbloomcapital/logo-mobile.png';
import AxeSecuritiesLogo from '../../old/images/brands/axesecurities/logo.png?url';
import AxeSecuritiesLogoRectangular from '../../old/images/brands/axesecurities/logo-mobile.png';
const BrandLogo = ({ useRectangular = false }) => {
    const { data: affiliateLogo, isLoading: affiliateLogoIsLoading } = useAppSelector((state) => state.greyLabelLogo);
    const getBrandLogo = () => {
        if (__BRAND__ === 'wintrado') {
            return useRectangular ? WebtraderLogoRectangular : WebtraderLogo;
        }
        else if (__BRAND__ === 'empirexinvest') {
            return useRectangular ? EmpirexInvestLogoRectangular : EmpirexInvestLogo;
        }
        else if (__BRAND__ === 'gfmarkets') {
            return useRectangular ? GFMarketsLogoRectangular : GFMarketsLogo;
        }
        else if (__BRAND__ === 'xbloomcapital') {
            return useRectangular ? XBloomCapitalLogoRectangular : XBloomCapitalLogo;
        }
        else if (__BRAND__.startsWith('axesecurities')) {
            return useRectangular ? AxeSecuritiesLogoRectangular : AxeSecuritiesLogo;
        }
        else {
            return useRectangular ? WebtraderLogoRectangular : WebtraderLogo;
        }
    };
    const logoUrl = useMemo(() => {
        const affiliateTypeLogo = useRectangular ? affiliateLogo === null || affiliateLogo === void 0 ? void 0 : affiliateLogo.rectangularLogo : affiliateLogo === null || affiliateLogo === void 0 ? void 0 : affiliateLogo.squareLogo;
        return affiliateTypeLogo !== null && affiliateTypeLogo !== void 0 ? affiliateTypeLogo : getBrandLogo();
    }, [affiliateLogo, useRectangular]);
    return (_jsx("div", Object.assign({ className: styles.wrapper }, { children: !affiliateLogoIsLoading && _jsx("img", { src: logoUrl, alt: `${__BRAND__}-logo`, className: styles.logo }) })));
};
export default React.memo(BrandLogo);
