import { FiBookOpen, FiCalendar } from 'react-icons/fi';
const config = {
    soundstyle: 'classic',
    appName: 'EDGE by Axe - Dev',
    appTitle: 'EDGE by Axe - Dev',
    feedApi: {
        url: 'wss://gateway.staging.wintrado.com',
    },
    baseURL: 'https://gateway.staging.wintrado.com',
    cashier: {
        useIframe: true,
        baseURL: 'https://cashier.staging.wintrado.com',
        depositURL: null,
        withdrawURL: null,
        registrationLink: null,
        loginLink: () => 'https://traderarea.yvf7mi3n2.app.axcera.io/platform-axe',
    },
    oAuth: {
        clientId: 'webtrader-pro-axesecurities',
        clientSecret: 'rpjsSJvu1HIJvDCe',
        authorizeURL: (lang, redirectUri, clientId, state, preselectWalletId) => `https://traderarea-api.yvf7mi3n2.app.axcera.io/cb/axe-sec/oauth/authorize?client_id=${encodeURIComponent(clientId)}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&state=${state}&scope=webtrader&lang=${lang}${preselectWalletId ? `&wallet_id=${preselectWalletId}` : ''}`,
        tokenURL: `https://traderarea-api.yvf7mi3n2.app.axcera.io/cb/axe-sec/oauth/token`,
    },
    // At least temporarily disabled
    //affiliatePortalUrl: 'https://affiliate.axesecurities.com',
    customPagesConfig: [
        {
            pathName: '/economic-calendar',
            titleKey: 'custom-page.economic-calendar',
            icon: FiCalendar,
            url: 'https://research.axesecurities.com/economic-calendar/',
        },
        {
            pathName: '/news',
            titleKey: 'custom-page.news',
            icon: FiBookOpen,
            url: 'https://research.axesecurities.com/news/',
        },
    ],
};
export default config;
