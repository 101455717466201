import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import balanceIcon from '../../assets/icons/wallet.svg?url';
import _t from 'counterpart';
import { formatCurrency } from 'utils/currency';
import { useAppSelector } from '../../storeHooks';
import styles from './BalanceStat.module.scss';
import classNames from 'classnames';
import Stat from 'components/Stat/Stat';
import LoadingDots from 'components/LoadingDots/LoadingDots';
const BalanceStat = ({ flexible = false, dark = false }) => {
    const balance = useAppSelector((state) => state.user.balance);
    const accountCurrency = useAppSelector((state) => state.user.accountCurrency);
    return (_jsx(Stat, { className: classNames(styles.balance, {
            [styles.flexible]: flexible,
        }), dark: dark, prepend: _jsx("img", { alt: "balance-icon", src: balanceIcon }), label: _t.translate('main.balance'), data: balance !== null ? formatCurrency(balance, accountCurrency) : _jsx(LoadingDots, {}) }));
};
export default React.memo(BalanceStat);
