var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getConfig } from '../utils/config';
import apiClient from './apiClient';
const getBaseURL = () => {
    const config = getConfig();
    if (!config) {
        throw Error('config not loaded');
    }
    return config.baseURL;
};
export const getCurrentCompetition = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.get(`${getBaseURL()}/competitions/current`);
    return response.data;
});
export const getAllCompetitions = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.get(`${getBaseURL()}/competitions`);
    return response.data;
});
export const joinCompetition = (nickname) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.post(`${getBaseURL()}/competitions/current/join`, { nickname });
    return response.data;
});
export const leaveCompetition = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.post(`${getBaseURL()}/competitions/current/leave`, {});
    return response.data;
});
export const getRanks = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.get(`${getBaseURL()}/competitions/current/ranks`);
    return response.data;
});
export const getLastNickname = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.get(`${getBaseURL()}/competitions/participations/last_nickname`);
    return response.data;
});
export const placeOrder = (placeOrder) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.post(`${getBaseURL()}/trading/orders`, placeOrder);
    return response.data;
});
export const editOrder = (_a) => __awaiter(void 0, void 0, void 0, function* () {
    var { orderId } = _a, rest = __rest(_a, ["orderId"]);
    const response = yield apiClient.patch(`${getBaseURL()}/trading/orders/${orderId}`, rest);
    return response.data.ok;
});
export const cancelOrder = (orderId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.delete(`${getBaseURL()}/trading/orders/${orderId}`);
    return response.data.ok;
});
export const editPosition = (_b) => __awaiter(void 0, void 0, void 0, function* () {
    var { positionId } = _b, rest = __rest(_b, ["positionId"]);
    const response = yield apiClient.patch(`${getBaseURL()}/trading/positions/${positionId}`, rest);
    return response.data;
});
export const getInstrumentPerformance = (symbol) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.get(`${getBaseURL()}/history/performance/${symbol}`);
    return response.data;
});
export const createPriceAlert = (createPriceAlert) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.post(`${getBaseURL()}/signals/price_alerts`, createPriceAlert);
    return true;
});
export const getPriceAlerts = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.get(`${getBaseURL()}/signals/price_alerts`);
    return response.data.priceAlerts;
});
export const deletePriceAlert = (id) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.delete(`${getBaseURL()}/signals/price_alerts/${id}`);
    return true;
});
export const getInstruments = (search, type, exchange) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.get(`${getBaseURL()}/trading/instruments`, {
        params: { search, type, exchange },
    });
    return response.data;
});
export const getInstrument = (symbol) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.get(`${getBaseURL()}/trading/instruments/${symbol}`);
    return response.data;
});
export const getInstrumentTrend = (symbol) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.get(`${getBaseURL()}/signals/trend/${symbol}`);
    return response.data;
});
export const getCandlesForSymbol = (symbol, start, end, resolution, countback) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.get(`${getBaseURL()}/history/candles/${symbol}`, { params: { start, end, resolution, countback } });
    return response.data;
});
