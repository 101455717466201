const config = {
    soundstyle: 'classic',
    appName: 'Wintrado Pro Dev',
    appTitle: 'Wintrado - WebTrader Pro Dev',
    feedApi: {
        url: 'wss://gateway.dev.wintrado.com',
    },
    baseURL: 'https://gateway.dev.wintrado.com',
    cashier: {
        useIframe: true,
        baseURL: 'https://cashier.dev.wintrado.com',
        depositURL: null,
        withdrawURL: null,
    },
    oAuth: {
        clientId: 'webtrader-pro-dev',
        clientSecret: 'qFaAPOfGIgBmUzxx',
    },
    affiliatePortalUrl: 'https://affiliate.dev.wintrado.com',
};
export default config;
