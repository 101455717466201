import { jsx as _jsx } from "react/jsx-runtime";
import config from 'config';
import { PageLayout } from '../global/PageLayout';
import _t from 'counterpart';
import { useAppSelector } from '../../../storeHooks';
import IframeWrapper from "components/IframeWrapper/IframeWrapper";
const PageProfile = () => {
    const token = useAppSelector((state) => state.user.token);
    const { profileURL } = config.cashier;
    if (!profileURL || !token)
        return null;
    const url = profileURL(token, _t.getLocale());
    return (_jsx(PageLayout, Object.assign({ className: "profile", isShowingEmbeddedIframe: true }, { children: _jsx(IframeWrapper, { url: url }) })));
};
export default PageProfile;
