import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import _t from 'counterpart';
import { NavLink } from 'react-router-dom';
import coinsIcon from '../../assets/icons/coins.svg?url';
import { useAppSelector } from '../../storeHooks';
import Stat from 'components/Stat/Stat';
import classNames from 'classnames';
import styles from './CoinsStat.module.scss';
import LoadingDots from 'components/LoadingDots/LoadingDots';
const CoinsStat = ({ flexible = false, dark = false }) => {
    const coinsBalance = useAppSelector((state) => state.user.coinsBalance);
    return (_jsx(NavLink, Object.assign({ to: "/coins", className: classNames({
            [styles.flexible]: flexible,
        }) }, { children: _jsx(Stat, { prepend: _jsx("img", { src: coinsIcon, alt: "coins-icon" }), label: _t.translate('main.coins'), data: coinsBalance !== null ? coinsBalance.toString() : _jsx(LoadingDots, {}), dark: dark }) })));
};
export default React.memo(CoinsStat);
