import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styles from './MobileEditOrderScreen.module.scss';
import MobileScreenLayout from '../../layout/MobileScreenLayout/MobileScreenLayout';
import _t from 'counterpart';
import { useGoBackTo } from '../../hooks/useGoBackTo';
import EditOrderForm from 'components/EditOrderForm/EditOrderForm';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../storeHooks';
import { isSet } from '../../helpers';
import Alert from 'components/Alert/Alert';
const MobileEditOrderScreen = () => {
    const { orderId } = useParams();
    const orders = useAppSelector((state) => state.orders);
    const orderToEdit = orders.find((order) => order.id === Number(orderId));
    const goBack = useGoBackTo('/trading/orders');
    return (_jsx(MobileScreenLayout, Object.assign({ className: styles.container, title: _t('main.edit_order'), onBackClick: goBack }, { children: isSet(orderToEdit) ? (_jsx(EditOrderForm, { onGoBack: goBack, order: orderToEdit })) : (_jsx(Alert, { variant: 'error', title: _t('error.oops'), description: _t('error.order_not_found') })) })));
};
export default React.memo(MobileEditOrderScreen);
